export const Settings = {
  setAdvertiserTrackingEnabled: async () => undefined,
  initializeSDK: () => undefined,
  setAppID: () => undefined,
  setAppName: () => undefined,
  setClientToken: () => undefined,
  setAdvertiserIDCollectionEnabled: () => undefined,
  setAutoLogAppEventsEnabled: () => undefined,
};

export const AppEventsLogger = {
  setUserID: (userId: string) => undefined,
  setUserData: (userData: Record<string, string>) => undefined,
  logEvent: (eventName: string, eventData: Record<string, string>) => undefined,
};
