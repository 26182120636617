import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { BootstrapStore } from '@ioupie/stores';

export const useBootstrapStore = (): BootstrapStore => {
  const bootstrap = useInjection<BootstrapStore>(STORE_TYPES.BOOTSTRAP);
  return useLocalObservable(() => bootstrap);
};
