import * as rudderanalytics from 'rudder-sdk-js';

type RudderInitOptions = rudderanalytics.loadOptions & {
  readonly dataPlaneUrl: string;
};

export default {
  setup: async (writeKey: string, { dataPlaneUrl, ...options }: RudderInitOptions) => {
    return Promise.resolve(
      rudderanalytics.load(writeKey, dataPlaneUrl, {
        ...options,
        // integrations: { AF: false },
      }),
    );
  },
  identify: async (userId: string, traits?: rudderanalytics.apiObject, options?: rudderanalytics.apiOptions) => {
    return Promise.resolve(rudderanalytics.identify(userId, traits, options));
  },
  screen: async (name: string, properties?: rudderanalytics.apiObject, options?: rudderanalytics.apiOptions) => {
    return Promise.resolve(rudderanalytics.page(name, properties, options));
  },
  track: async (event: string, properties?: rudderanalytics.apiObject, options?: rudderanalytics.apiOptions) => {
    return Promise.resolve(rudderanalytics.track(event, properties, options));
  },
};
