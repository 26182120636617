export enum COLOR_OPTIONS {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum MODE_COLORS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  BACKGROUND = 'background',
  TEXT = 'text',
  TEXT2 = 'text2',
  TEXT3 = 'text3',
  TITLE = 'title',
  LINK = 'link',
  ERROR = 'error',
}

export declare type ColorMode = Record<MODE_COLORS, string>;

const LIGHT_MODE: ColorMode = {
  [MODE_COLORS.PRIMARY]: '#ffd201',
  [MODE_COLORS.SECONDARY]: '#373737',
  [MODE_COLORS.TERTIARY]: '#6304ee',
  [MODE_COLORS.BACKGROUND]: '#fff',
  [MODE_COLORS.TEXT]: '#404040',
  [MODE_COLORS.TEXT2]: '#c5c6d0',
  [MODE_COLORS.TEXT3]: '#808080',
  [MODE_COLORS.TITLE]: '#000',
  [MODE_COLORS.LINK]: '#0645ad',
  [MODE_COLORS.ERROR]: '#f00',
} as const;

const DARK_MODE: ColorMode = {
  [MODE_COLORS.PRIMARY]: '#fcc000',
  [MODE_COLORS.SECONDARY]: '#404040',
  [MODE_COLORS.TERTIARY]: '#cf9fff',
  [MODE_COLORS.BACKGROUND]: '#1f1f1f',
  [MODE_COLORS.TEXT]: '#c5c6d0',
  [MODE_COLORS.TEXT2]: '#333333',
  [MODE_COLORS.TEXT3]: '#808080',
  [MODE_COLORS.TITLE]: '#fff',
  [MODE_COLORS.LINK]: '#58a6ff',
  [MODE_COLORS.ERROR]: '#f00',
} as const;

export declare type ColorConstants = Record<COLOR_OPTIONS, ColorMode>;

export const colors = Object.freeze<ColorConstants>({
  [COLOR_OPTIONS.LIGHT]: LIGHT_MODE,
  [COLOR_OPTIONS.DARK]: DARK_MODE,
} as const);
