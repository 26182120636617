import { COLOR_OPTIONS } from '@ioupie/shared/constants';
import { useTheme as useStyledComponentsTheme } from 'styled-components/native';

type ThemeData = {
  readonly mode: 'light' | 'dark';
};

export const useTheme = (): ThemeData => {
  try {
    const { mode = COLOR_OPTIONS.LIGHT } = useStyledComponentsTheme() ?? {};

    return { mode };
  } catch (error) {
    console.error('Failed to use theme with error', error);
    return { mode: COLOR_OPTIONS.LIGHT };
  }
};
