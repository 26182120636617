import { inject, injectable, postConstruct } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { v4 as uuidV4 } from 'uuid';

import { ErrorService, RestService, type AnalyticsService } from '@ioupie/services';
import {
  AnalyticsEvents,
  BUSINESS_AREA_OPTIONS,
  BUSINESS_SEGMENTS_OPTIONS,
  CREATE_ORDER_VALIDATION_OPTIONS,
  PATH_PARAMS,
  QUERY_PARAMS,
  SERVICE_TYPES,
  SHOP_STEPS,
  STORE_TYPES,
  endpoints,
  routes,
} from '@ioupie/shared/constants';
import type {
  CatalogItem,
  CouponInformation,
  ErrorMessages,
  Optional,
  OrderTicket,
  ProductAmount,
  ProductData,
  ProductGroup,
  ProductMetadata,
  ProductSelection,
  ProductsCatalog,
  Provider,
  UrlEndpoint,
} from '@ioupie/shared/models';
import {
  CatalogFoundProductsRequestPayload,
  CatalogFoundProductsResponsePayload,
  CatalogProductGroupsResponsePayload,
} from '@ioupie/shared/payloads';
import { RequestCouponValidationPayload } from '@ioupie/shared/payloads/precificador.payloads';
import { safeArrayLookup, safeObjectLookup } from '@ioupie/shared/utils';

import { AddressStore } from './address.store';
import { AuthStore } from './auth.store';
import { DeliveryStore } from './delivery.store';
import { LockersStore } from './lockers.store';
import { SettingsStore } from './settings.store';

@injectable()
export class ShopsStore {
  private static readonly CUSTOM_PRODUCT_AMOUNT: Readonly<number> = 1;

  @inject(SERVICE_TYPES.REST)
  private readonly restService: RestService;
  @inject(SERVICE_TYPES.ERROR)
  private readonly errorService: ErrorService;
  @inject(SERVICE_TYPES.ANALYTICS.COMPOSITE)
  private readonly analyticsService: AnalyticsService;

  @inject(STORE_TYPES.DELIVERY)
  private readonly deliveryStore: DeliveryStore;
  @inject(STORE_TYPES.LOCKERS)
  private readonly lockersStore: LockersStore;
  @inject(STORE_TYPES.SETTINGS)
  private readonly settingsStore: SettingsStore;
  @inject(STORE_TYPES.ADDRESS)
  private readonly addressStore: AddressStore;
  @inject(STORE_TYPES.AUTH)
  private readonly authStore: AuthStore;

  @observable public loading: boolean = false;
  @observable public loadingCoupon: boolean = false;
  @observable public errors: ErrorMessages = [];
  @observable public couponErrors: ErrorMessages = [];

  @observable public selectedProviderId?: string;
  @observable public shopProvider?: Provider;

  @observable public productsCatalog: ProductsCatalog = {};
  @observable public selectedProducts: Map<string, ProductAmount> = new Map();
  @observable public selectedProductCount: number = 0;
  @observable public filterQuery: string = '';

  @observable public shopStep: SHOP_STEPS = SHOP_STEPS.CATEGORIES;
  @observable public categorySelected: string = '';

  @observable public viewDetailsProviderId?: string;

  @observable public customizingProduct?: ProductData;

  @observable public coupon?: string = '';
  @observable public couponInformation?: CouponInformation;

  @observable public shopBusinessArea: BUSINESS_AREA_OPTIONS = BUSINESS_AREA_OPTIONS.DELIVERY;
  @observable public shopSegment: BUSINESS_SEGMENTS_OPTIONS = BUSINESS_SEGMENTS_OPTIONS.LAUNDRY;

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public selectProductToCustomize(product: ProductData): void {
    this.customizingProduct = product;
  }

  @action
  public clearCustomizingProduct(): void {
    this.customizingProduct = undefined;
  }

  @action
  public selectProviderToViewDetails(providerId?: string): void {
    this.viewDetailsProviderId = providerId;
  }

  @action
  public clearErrors(): void {
    this.errors = [];
  }

  @action
  public changeStep(step: SHOP_STEPS, logEvent?: true): void {
    this.shopStep = step;

    if (logEvent) {
      this.analyticsService.setScreen(`${routes.stacks.orders}/${routes.pages.orders.catalog}/${step.toString()}`);
    }
  }

  @action
  public selectCategory(category: string): void {
    this.categorySelected = category;
    this.analyticsService.setScreen(`${routes.stacks.orders}/${routes.pages.orders.catalog}/Categoria/${category}`);

    // AppsFlyer
    this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.LIST_VIEW, {
      af_content_type: category,
    });
  }

  @action
  public setShopBusinessArea(area: BUSINESS_AREA_OPTIONS): void {
    this.shopBusinessArea = area;
  }

  @action
  public setShopSegment(segment: BUSINESS_SEGMENTS_OPTIONS): void {
    this.shopSegment = segment;
  }

  @action
  public selectShopProvider(id: string): void {
    this.selectedProviderId = id;
    this.shopProvider = this.availableProviders.find((provider) => provider.id === id);
  }

  @action
  public clearSelectedProducts(): void {
    this.selectedProducts = new Map();
    this.selectedProductCount = 0;
  }

  @action
  public addProduct(product: ProductData): void {
    // if this product has not been selected, default to zero
    const { amount: previous = 0 } = this.selectedProducts.get(product.productId) ?? {};
    this.selectedProducts.set(product.productId, { product, referenceId: product.productId, amount: previous + 1 });
    this.selectedProductCount++;
    const updatedQuantity = previous + 1;

    this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.ADD_TO_CART, {
      // Google
      currency: 'BRL',
      value: product.minPrice.amount * updatedQuantity,
      items: [
        {
          item_id: product.productId,
          item_name: product.name,
          price: product.minPrice.amount,
          quantity: updatedQuantity,
        },
      ],

      // AppsFlyer
      af_price: product.minPrice.amount,
      af_currency: 'BRL',
      af_content: product.name,
      af_content_id: product.productId,
      af_quantity: updatedQuantity,
    });
  }

  @action
  public subProduct(product: ProductData): void {
    // if this product has not been selected, default to zero
    const { amount: previous = 0 } = this.selectedProducts.get(product.productId) ?? {};
    this.selectedProducts.set(product.productId, { product, referenceId: product.productId, amount: previous - 1 });
    this.selectedProductCount--;
    const updatedQuantity = previous - 1;

    // Google
    this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.REMOVE_FROM_CART, {
      // Google
      currency: 'BRL',
      value: product.minPrice.amount,
      items: [
        {
          item_id: product.productId,
          item_name: product.name,
          price: product.minPrice.amount,
          quantity: updatedQuantity,
        },
      ],

      // AppsFlyer
      af_price: product.minPrice.amount,
      af_currency: 'BRL',
      af_content: product.name,
      af_content_id: product.productId,
      af_quantity: updatedQuantity,
    });
  }

  @action
  public removeProduct(referenceId: string): void {
    const product = this.selectedProducts.get(referenceId);
    this.selectedProducts.delete(referenceId);
    this.selectedProductCount -= product?.amount ?? 0;
    this.clearCoupon();

    if (product) {
      this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.REMOVE_FROM_CART, {
        // Google
        currency: 'BRL',
        value: product.amount,
        items: [
          {
            item_id: product.product.productId,
            item_name: product.product.name,
            price: product.amount,
          },
        ],

        // AppsFlyer
        af_price: product.product.minPrice.amount,
        af_currency: 'BRL',
        af_content: product.product.name,
        af_content_id: product.product.productId,
        af_quantity: product.amount,
      });
    }
  }

  @action
  public setFilterQuery(query: string): void {
    this.filterQuery = query;
    if (query && query.length > 0) {
      this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.SEARCH, {
        search_term: query,
        af_search_string: query,
      });
    }
  }

  @action
  public setCoupon(coupon: string): void {
    this.coupon = coupon;
    this.couponErrors = [];
  }

  @action
  public clearCoupon(): void {
    this.coupon = '';
    this.couponErrors = [];
    this.couponInformation = {
      eligible: false,
      totalDiscount: { amount: 0, currencyCode: 'BRL' },
      orderGrandTotal: { amount: 0, currencyCode: 'BRL' },
      message: '',
    };
  }

  @action
  public addCustomProduct(metadata: ProductMetadata): void {
    if (this.customizingProduct) {
      // enhance the custom product with metadata
      const customProduct: ProductData = {
        ...this.customizingProduct,
        metadata,
      };

      const referenceId = uuidV4();

      this.selectedProducts.set(referenceId, {
        referenceId,
        product: customProduct,
        amount: ShopsStore.CUSTOM_PRODUCT_AMOUNT,
      });

      this.selectedProductCount++;

      this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.ADD_TO_CART, {
        // Google
        currency: 'BRL',
        value: customProduct.minPrice.amount,
        items: [
          {
            item_id: customProduct.productId,
            item_name: customProduct.name,
            price: customProduct.minPrice.amount,
            quantity: ShopsStore.CUSTOM_PRODUCT_AMOUNT,
            metadata,
          },
        ],

        // AppsFlyer
        af_price: customProduct.minPrice.amount,
        af_currency: 'BRL',
        af_content: customProduct.name,
        af_content_id: customProduct.productId,
        af_quantity: ShopsStore.CUSTOM_PRODUCT_AMOUNT,
      });
    }
  }

  @action
  public async fetchShopCatalog(shopId = this.selectedProviderId): Promise<void> {
    this.loading = true;

    if (!shopId) {
      runInAction(() => {
        this.loading = false;
      });

      return;
    }

    try {
      const groupingsUrl: UrlEndpoint = {
        url: endpoints.catalog.products.groups,
        queryParams: {
          [QUERY_PARAMS.LANGUAGE]: { language: this.settingsStore.normalizedLanguage },
        },
      };

      const productsUrl: UrlEndpoint = {
        url: endpoints.catalog.products.find,
      };

      const productsBody: CatalogFoundProductsRequestPayload = {
        language: this.settingsStore.normalizedLanguage,
        serviceProviderIds: [shopId],
        lockerId:
          this.shopBusinessArea === BUSINESS_AREA_OPTIONS.LOCKER ? this.lockersStore.lockerProvider?.id : undefined,
      };

      const [shopGroupings, shopCatalog] = await Promise.all([
        this.restService.get<CatalogProductGroupsResponsePayload>(groupingsUrl),
        this.restService.post<CatalogFoundProductsRequestPayload, CatalogFoundProductsResponsePayload>(
          productsUrl,
          productsBody,
        ),
      ]);

      // reduce it all into a reasonable way
      // remove any empty categories
      const productsCatalog = shopGroupings.productGroups.reduce(
        (catalog: Readonly<ProductsCatalog>, group: ProductGroup) => {
          const products = safeObjectLookup(shopCatalog.productsByGroup, group.groupId) ?? [];

          // no products for this category, just bypass
          if (products.length <= 0) {
            return catalog;
          }

          const catalogItem: CatalogItem = {
            ...group,
            products: products.map((product) => ({
              ...product,
              catalogName: group.name,
              catalogBreak: false,
            })),
            highlight: products.some((product) => product.highlight ?? false),
          };

          return {
            ...catalog,
            [group.groupId]: catalogItem,
          };
        },
        {},
      );

      this.analyticsService.trackEvent(AnalyticsEvents.SHOPS_FETCH_CATALOG, { shopId });

      runInAction(() => {
        this.productsCatalog = productsCatalog;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public createOrderTicket(): Optional<OrderTicket> {
    if (!this.shopProvider) {
      this.errors = this.errorService.wrapApiError(new Error('empty service provider id'));
      return undefined;
    }

    // this.analyticsService.registerEvent('Shops_Ticket_Create', {
    //   segment: this.shopSegment,
    //   type: this.shopBusinessArea,
    //   serviceProviderId: this.shopProvider.id,
    // });

    return {
      products: this.productsSummary.reduce(
        (products, nextProduct) => ({
          ...products,
          [nextProduct.referenceId]: nextProduct,
        }),
        {},
      ),
      segment: this.shopSegment,
      type: this.shopBusinessArea,
      serviceProviderId: this.shopProvider.id,
      lockerId:
        this.shopBusinessArea === BUSINESS_AREA_OPTIONS.LOCKER ? this.lockersStore.lockerProvider?.id : undefined,
      coupon: this.coupon,
    };
  }

  @action
  public async validateCoupon(ticket: OrderTicket, coupon: string, inboundFee?: number): Promise<void> {
    this.loadingCoupon = true;
    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.precificador.coupon.validate,
        pathParams: { [PATH_PARAMS.USER_ID]: this.authStore.username },
      };

      const payload: RequestCouponValidationPayload = {
        ...ticket,
        coupon,
        inboundFee,
      };

      const couponInformation = await this.restService.post<RequestCouponValidationPayload, CouponInformation>(
        urlEndpoint,
        payload,
        this.authStore.buildAuthHeaders(),
      );

      runInAction(() => {
        this.loadingCoupon = false;
        this.couponInformation = couponInformation;
      });

      this.analyticsService.trackEvent(AnalyticsEvents.SHOPS_VALIDATE_COUPON, { couponId: coupon });
    } catch (error) {
      runInAction(() => {
        this.couponErrors = this.errorService.wrapApiError(error);
        this.loadingCoupon = false;
        this.coupon = '';
        this.couponInformation = undefined;
      });
    }
  }

  @action
  public checkAndAutoSelectOnlyCategory(): void {
    const categories = Object.values(this.productsCatalog);

    if (categories.length === 1) {
      const catalog = safeArrayLookup(categories, 0);

      if (catalog) {
        this.selectCategory(catalog.groupId);
        this.changeStep(SHOP_STEPS.PRODUCTS);
      }
    }
  }

  @computed
  public get isShopSingleCategory(): boolean {
    const categories = Object.values(this.productsCatalog);
    return categories.length === 1;
  }

  @computed
  public get availableProviders(): readonly Provider[] {
    if (this.shopBusinessArea === BUSINESS_AREA_OPTIONS.DELIVERY) {
      const { deliveryProviders = [] } = this.deliveryStore;
      return deliveryProviders;
    }

    if (this.shopBusinessArea === BUSINESS_AREA_OPTIONS.LOCKER) {
      const { lockerProvider } = this.lockersStore;
      const { providers = [] } = lockerProvider ?? {};

      return providers;
    }

    return [];
  }

  @computed
  public get productsSummary(): readonly ProductAmount[] {
    return Array.from(this.selectedProducts.values()).filter((product) => product.amount > 0);
  }

  @computed
  public get selectionInfo(): ProductSelection {
    const initialState: ProductSelection = {
      totalPrice: 0,
      totalSelected: 0,
      minimumDays: 0,
    };

    return this.productsSummary.reduce((selection, next) => {
      // if this product is customizable and has an estimated price, use it, otherwise defaults to min price
      const chosenPrice = next.product.metadata?.estimatedPrice ?? next.product.minPrice.amount;
      const amountPrice = next.amount * chosenPrice;

      return {
        totalPrice: selection.totalPrice + amountPrice,
        totalSelected: selection.totalSelected + next.amount,
        minimumDays: next.product.minDays > selection.minimumDays ? next.product.minDays : selection.minimumDays,
      };
    }, initialState);
  }

  @computed
  public get productsCategorized(): readonly ProductData[] {
    const { products = [] } = safeObjectLookup(this.productsCatalog, this.categorySelected) ?? {};
    return products;
  }

  private normalizeSearchParameter(parameter: string): string {
    return (parameter ?? '')
      .trim()
      .normalize('NFD')
      .toLowerCase()
      .replace(/[\u0300-\u036f]/g, '');
  }

  @computed
  public get productsFiltered(): readonly ProductData[] {
    if (!this.filterQuery) {
      return [];
    }

    const normalizedFilterQuery = this.normalizeSearchParameter(this.filterQuery);

    return Object.values(this.productsCatalog).reduce((products: readonly ProductData[], catalogItem) => {
      const productsFound = catalogItem.products
        .filter((product) => this.normalizeSearchParameter(product.name).includes(normalizedFilterQuery))
        .map((product, index) => ({
          ...product,
          catalogBreak: index === 0,
        }));

      return [...products, ...productsFound];
    }, []);
  }

  @computed
  public get isShopAreaAvailable(): boolean {
    const isNotDelivery = this.shopBusinessArea !== BUSINESS_AREA_OPTIONS.DELIVERY;
    const isAddressSetup = Boolean(this.addressStore.selectedAddressInfo);
    const { eligible = false } = this.shopProvider ?? {};

    return isNotDelivery || (isAddressSetup && eligible);
  }

  @computed
  public get isShopMinProductsOrderValid(): boolean {
    const { metadata } = this.shopProvider ?? {};
    const { minProducts = 0 } = metadata ?? {};
    const { totalSelected = 0 } = this.selectionInfo ?? {};

    return minProducts > 0 && totalSelected >= minProducts;
  }

  @computed
  public get isShopMinPriceOrderValid(): boolean {
    const { metadata } = this.shopProvider ?? {};
    const { minValue } = metadata ?? {};
    const { amount: minAmount = 0 } = minValue ?? {};
    const { totalPrice = 0 } = this.selectionInfo ?? {};

    return minAmount > 0 && totalPrice >= minAmount;
  }

  @computed
  public get isShopZeroValidated(): boolean {
    const { metadata } = this.shopProvider ?? {};
    const { minValue, minProducts = 0 } = metadata ?? {};
    const { amount: minAmount = 0 } = minValue ?? {};
    const { totalSelected = 0, totalPrice = 0 } = this.selectionInfo ?? {};

    // this shop provider doesn't have any minimal requirements
    const shopWithoutValidations = minProducts <= 0 && minAmount <= 0;
    // this order has content
    const nonZeroOrder = totalSelected > 0 && totalPrice > 0;

    return shopWithoutValidations && nonZeroOrder;
  }

  @computed
  public get areShopRequirementsValid(): boolean {
    const { metadata } = this.shopProvider ?? {};
    const { validationType = CREATE_ORDER_VALIDATION_OPTIONS.OR } = metadata ?? {};

    if (this.isShopZeroValidated) {
      return true;
    }

    switch (validationType) {
      case CREATE_ORDER_VALIDATION_OPTIONS.OR:
        return this.isShopMinProductsOrderValid || this.isShopMinPriceOrderValid;
      case CREATE_ORDER_VALIDATION_OPTIONS.XOR:
        return (
          (this.isShopMinPriceOrderValid && !this.isShopMinProductsOrderValid) ||
          (!this.isShopMinPriceOrderValid && this.isShopMinProductsOrderValid)
        );
      case CREATE_ORDER_VALIDATION_OPTIONS.AND:
        return this.isShopMinProductsOrderValid && this.isShopMinPriceOrderValid;
      default:
        return false;
    }
  }

  @computed
  public get totalPrice(): number {
    return this.selectionInfo?.totalPrice ?? 0;
  }

  @computed
  public get couponDiscount(): number {
    return this.couponInformation?.totalDiscount?.amount ?? 0;
  }

  @computed
  public get estimatedPrice() {
    return this.totalPrice - this.couponDiscount;
  }

  @computed
  public get isShopOrderValid(): boolean {
    return this.isShopAreaAvailable && this.areShopRequirementsValid;
  }

  @computed
  public get isDelivery(): boolean {
    return this.shopBusinessArea === BUSINESS_AREA_OPTIONS.DELIVERY;
  }

  @computed
  public get viewProviderDetails() {
    return this.viewDetailsProviderId
      ? this.deliveryStore.deliveryProviders.find((provider) => provider.id === this.viewDetailsProviderId)
      : undefined;
  }
}
