import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { UserStore } from '@ioupie/stores';

export const useUserStore = (): UserStore => {
  const user = useInjection<UserStore>(STORE_TYPES.USER);
  return useLocalObservable(() => user);
};
