import { memo, useCallback, useState } from 'react';

import { useTheme } from '@ioupie/hooks';
import { colors } from '@ioupie/shared/constants';

import { TextInput } from '../text-input';
import { PasswordInputProps } from './password-input.models';
import * as S from './password-input.styles';

export default memo((props: PasswordInputProps) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const eyeIcon = showPassword ? 'eye-off' : 'eye';
  const eyeColor = theme.mode === 'dark' ? colors.dark.text : colors.light.text;

  return (
    <S.PasswordContainer>
      <TextInput {...props} secureTextEntry={!showPassword} />
      <S.EyeIconButton icon={eyeIcon} iconColor={eyeColor} size={20} onPress={toggleShowPassword} />
    </S.PasswordContainer>
  );
});
