import { UniversalImage } from '@ioupie/components/gallery';
import styled from 'styled-components/native';

export const IntroUsage = styled.View`
  margin: 6px 20px;
  padding: 10px 0;
`;

export const ProviderImage = styled(UniversalImage)`
  border-radius: 45px;
  height: 75px;
  margin-right: 10px;
  width: 75px;
`;
