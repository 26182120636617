import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { SegmentsStore } from '@ioupie/stores';

export const useSegmentsStore = (): SegmentsStore => {
  const segments = useInjection<SegmentsStore>(STORE_TYPES.SEGMENTS);
  return useLocalObservable(() => segments);
};
