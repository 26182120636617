import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@ioupie/components/typography';

import { ListMessageProps } from './message.models';
import * as S from './message.styles';

/**
 * @function ListMessageComponent
 */
export default memo(({ title = '', subtitle = '', style = {} }: ListMessageProps) => {
  const [t] = useTranslation();

  return (
    <S.MessageBody style={style}>
      <S.ItemTitle>{t(title)}</S.ItemTitle>
      <Text>{t(subtitle)}</Text>
    </S.MessageBody>
  );
});
