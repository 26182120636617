import { createDrawerNavigator } from '@react-navigation/drawer';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';

import { useBootstrapStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

import { HeaderContentDrawer, MenuContentDrawer } from './drawer';
import { AddressStack, AuthStack, HelpStack, OrdersStack, PaymentStack, ProfileStack, SettingsStack } from './stacks';

const Drawer = createDrawerNavigator();

/**
 * @function AppRoutes
 */
export default observer(() => {
  const bootstrapStore = useBootstrapStore();

  useEffect(() => {
    // eslint-disable-next-line no-void
    void bootstrapStore.loadApplication();
  }, []);

  if (!bootstrapStore.appReady) {
    return <Fragment />;
  }

  return (
    <Drawer.Navigator
      initialRouteName={routes.stacks.orders}
      screenOptions={{ header: HeaderContentDrawer }}
      drawerContent={MenuContentDrawer}>
      <Drawer.Screen name={routes.stacks.orders} component={OrdersStack} />
      <Drawer.Screen name={routes.stacks.auth} component={AuthStack} />
      <Drawer.Screen name={routes.stacks.payment} component={PaymentStack} />
      <Drawer.Screen name={routes.stacks.help} component={HelpStack} />
      <Drawer.Screen name={routes.stacks.settings} component={SettingsStack} />
      <Drawer.Screen name={routes.stacks.profile} component={ProfileStack} />
      <Drawer.Screen name={routes.stacks.address} component={AddressStack} />
    </Drawer.Navigator>
  );
});
