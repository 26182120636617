import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { HeaderStore } from '@ioupie/stores';

export const useHeaderStore = (): HeaderStore => {
  const header = useInjection<HeaderStore>(STORE_TYPES.HEADER);
  return useLocalObservable(() => header);
};
