import { Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { colors, fontsFamily } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

declare type BoldProps = {
  readonly black?: boolean;
  readonly white?: boolean;
};

export default styled(Text)<BoldProps>`
  color: ${(props: BoldProps) => {
    if (props.black) {
      return '#000';
    }

    if (props.white) {
      return '#fff';
    }

    return themefy({
      light: colors.light.text,
      dark: colors.dark.text,
    })(props);
  }};
  font-family: ${fontsFamily.bold};
`;
