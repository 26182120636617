import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ioupie/components/gallery';
import { ScrollView } from '@ioupie/components/layout';
import { useNavigationStore, useOrdersStore, useShopsStore, useTheme } from '@ioupie/hooks';
import { BUSINESS_SEGMENTS_OPTIONS, ORDERING_STEPS, colors, routes } from '@ioupie/shared/constants';

import { Basket } from '../basket';
import { Summary } from '../summary';
import * as S from './basket-step.styles';

/**
 * @function BasketStep
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();
  const ordersStore = useOrdersStore();
  const navigationStore = useNavigationStore();
  const theme = useTheme();

  const { shopProvider } = shopsStore;
  const { metadata } = shopProvider ?? {};
  const { minProducts = 0, minValue } = metadata ?? {};
  const { amount: minAmount = 0, currencyCode = '' } = minValue ?? {};

  const orderValidation = !shopsStore.isShopOrderValid && (
    <S.ProviderNotEligibleMessage>
      {!shopsStore.isShopAreaAvailable && (
        <S.InvalidMessageTextAndButton>
          <S.InvalidMessage>
            <Icon name="map-marker-off-outline" size={28} />
            <S.InvalidMessageText>
              {t('containers.orders.create-order.provider-not-eligible-label')}
            </S.InvalidMessageText>
          </S.InvalidMessage>
          <S.ConfigureAddressButton
            textColor={theme.mode === 'dark' ? colors.dark.tertiary : colors.light.tertiary}
            mode="text"
            onPress={() =>
              navigationStore.dispatchNavigation({
                stack: routes.stacks.address,
                screen: routes.pages.address.addresses_list,
              })
            }>
            {t('containers.orders.create-order.provider-not-eligible-button')}
          </S.ConfigureAddressButton>
        </S.InvalidMessageTextAndButton>
      )}
      {!shopsStore.isShopMinProductsOrderValid && minProducts > 0 && (
        <S.InvalidMessage>
          <Icon name="cart-off" size={28} />
          <S.InvalidMessageText>
            {t('containers.orders.create-order.provider-minimum-selection', { minProducts })}
          </S.InvalidMessageText>
        </S.InvalidMessage>
      )}
      {!shopsStore.isShopMinPriceOrderValid && minAmount > 0 && (
        <S.InvalidMessage>
          <Icon name="currency-usd-off" size={28} />
          <S.InvalidMessageText>
            {t('containers.orders.create-order.provider-minimum-amount', { currencyCode, minAmount })}
          </S.InvalidMessageText>
        </S.InvalidMessage>
      )}
    </S.ProviderNotEligibleMessage>
  );

  return (
    <ScrollView>
      <Summary />
      <Basket readOnly={false} />
      {orderValidation}
      <S.NextButton
        text={t('containers.orders.ordering.next-button')}
        onPress={() => {
          const nextStep =
            shopsStore.shopProvider?.segment === BUSINESS_SEGMENTS_OPTIONS.GOODS
              ? ORDERING_STEPS.PAYMENT
              : ORDERING_STEPS.SCHEDULE;
          ordersStore.changeOrderingStep(nextStep);
        }}
        disabled={shopsStore.productsSummary.length === 0 || !shopsStore.isShopOrderValid}
      />
    </ScrollView>
  );
});
