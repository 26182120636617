import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Counter, Customize } from '@ioupie/components/custom';
import { useSettingsStore, useShopsStore } from '@ioupie/hooks';
import { SHOP_STEPS, colors } from '@ioupie/shared/constants';

import { ProductLegendProps } from './product-legend.models';
import * as S from './product-legend.styles';

/**
 * @function ProductLegendContainer
 */
export default observer(({ product }: ProductLegendProps) => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();
  const settingsStore = useSettingsStore();

  const { amount = 0 } = shopsStore.selectedProducts.get(product.productId) ?? {};
  const counterColor = settingsStore.darkMode ? colors.dark.text : colors.light.text;

  const addProduct = useCallback((): void => {
    shopsStore.addProduct(product);
  }, [product]);

  const subProduct = useCallback((): void => {
    shopsStore.subProduct(product);
  }, [product]);

  const customizeProduct = useCallback((): void => {
    shopsStore.selectProductToCustomize(product);
    shopsStore.changeStep(SHOP_STEPS.CUSTOM);
  }, [product]);

  return (
    <S.ProductLegend>
      <S.ProductPriceLabel>
        {t('containers.catalog.shop-catalog.products.product-legend.price', {
          price: product.minPrice.amount.toFixed(2),
        })}
        <S.UnitOfMeasurementLabel>
          {' / '}
          {t(
            `containers.catalog.shop-catalog.products.product-legend.unit-of-measurement.${
              product.unitOfMeasurement ?? 'DEFAULT'
            }`,
          )}
        </S.UnitOfMeasurementLabel>
      </S.ProductPriceLabel>
      {product.customizable ? (
        <Customize
          icon="circle-edit-outline"
          onPress={customizeProduct}
          text={t('containers.catalog.shop-catalog.products.product-legend.customize')}
        />
      ) : (
        <Counter preventNegatives value={amount} onAdd={addProduct} onSub={subProduct} iconColor={counterColor} />
      )}
    </S.ProductLegend>
  );
});
