import axios, { HttpStatusCode } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { inject, injectable, postConstruct, preDestroy } from 'inversify';
import type { IReactionDisposer } from 'mobx';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';

import { restClient } from '@ioupie/client';
import { ErrorService, RestService, type AnalyticsService, type StorageService } from '@ioupie/services';
import {
  AnalyticsEvents,
  PATH_PARAMS,
  SERVICE_TYPES,
  STORAGE_KEYS,
  USER_STEPS,
  endpoints,
  routes,
} from '@ioupie/shared/constants';
import type { AuthHeaders, AuthMetaData, AuthTokens, ErrorMessages, UserAttributes } from '@ioupie/shared/models';
import { BaseUser, UrlEndpoint, User, UserTokens } from '@ioupie/shared/models';
import {
  CheckUserRequestPayload,
  ConfirmAccountRequestPayload,
  RecoverAccountRequestPayload,
  RefreshSessionPayload,
  SignInRequestPayload,
  SignOutResponsePayload,
  SignUpResponsePayload,
  VerificationCodePayload,
} from '@ioupie/shared/payloads';
import { isNotEmpty } from '@ioupie/shared/utils';

@injectable()
export class AuthStore {
  // one hour: 1000 * 60 * 60
  private static readonly TOKEN_REFRESH_RATE_IN_MILLISECONDS: number = 3600000;

  @inject(SERVICE_TYPES.STORAGE.SECURE_STORAGE)
  private readonly storageService: StorageService;
  @inject(SERVICE_TYPES.REST)
  private readonly restService: RestService;
  @inject(SERVICE_TYPES.ERROR)
  private readonly errorService: ErrorService;
  @inject(SERVICE_TYPES.ANALYTICS.COMPOSITE)
  private readonly analyticsService: AnalyticsService;

  @observable private refreshDisposer?: IReactionDisposer;

  @observable private authTokens?: AuthTokens;
  @observable private authMetaData?: AuthMetaData;

  @observable public loading: boolean = false;
  @observable public errors: ErrorMessages = [];

  @observable public showAuthModal: boolean = false;
  @observable public userFirstName: string = '';

  // initializes as true, and sets to false right after trying to refresh user tokens
  @observable public bootstrappingUserTokens: boolean = true;

  @observable public username: string = '';
  @observable public userStep: USER_STEPS = USER_STEPS.CHECK_USERNAME;

  // internal management only, auto logon after signup purposes
  private password: Readonly<string> = '';
  private isNewAccount: Readonly<boolean> = false;

  @postConstruct()
  public init(): void {
    const self = makeObservable(this);

    // run the refresh once manually
    runInAction(() => {
      // refresh the tokens data every AuthStore.TOKEN_REFRESH_RATE_IN_MILLISECONDS
      this.refreshDisposer = self.scheduleSessionRefresh();
    });

    // intercepts 401 and trigger a refresh
    createAuthRefreshInterceptor(
      restClient,
      async () => {
        // eslint-disable-next-line no-void
        void self.refreshUserTokens();
      },
      {
        statusCodes: [HttpStatusCode.Unauthorized],
        pauseInstanceWhileRefreshing: true,
      },
    );
  }

  @preDestroy()
  public close(): void {
    if (this.refreshDisposer) {
      this.refreshDisposer();
    }
  }

  @action.bound
  private scheduleSessionRefresh(): IReactionDisposer {
    return reaction(
      () => this.authTokens,
      () => {
        // eslint-disable-next-line no-void
        void this.refreshUserTokens();
      },
      {
        fireImmediately: true,
        scheduler: (run) => setTimeout(run, AuthStore.TOKEN_REFRESH_RATE_IN_MILLISECONDS),
      },
    );
  }

  @action
  public evidenceUsername(email: string): void {
    this.username = email;
  }

  @action
  public changeStep(step: USER_STEPS, logEvent: boolean = true): void {
    this.userStep = step;
    if (logEvent) {
      this.logStepNavigation(step);
    }
  }

  @action
  public dispatchAnalyticsEvent(event: AnalyticsEvents): void {
    this.analyticsService.trackEvent(event, { tick: '1' });
  }

  @action
  public logStepNavigation(step: USER_STEPS): void {
    this.analyticsService.setScreen(`${routes.stacks.auth}/${routes.pages.auth.sign_in}/${step.toString()}`);
  }

  @action
  public clearErrors(): void {
    this.errors = [];
  }

  @action
  public clearUsername(): void {
    this.userStep = USER_STEPS.CHECK_USERNAME;
    this.logStepNavigation(USER_STEPS.CHECK_USERNAME);
    this.username = '';
  }

  @action
  public setShowAuthModal(show: boolean): void {
    this.showAuthModal = show;
  }

  @action
  public async checkUsername(userEmail: string): Promise<void> {
    this.loading = true;
    // sanitize the email
    const normalizedEmail = (userEmail ?? '').toLowerCase().trim();
    try {
      const userData = await this.restService.get<CheckUserRequestPayload>(
        {
          url: endpoints.portaria.auth.verify_user,
          pathParams: { [PATH_PARAMS.USER_ID]: normalizedEmail },
        },
        undefined,
        Infinity,
      );

      runInAction(() => {
        this.username = normalizedEmail;
        this.userFirstName = userData.name;
        this.userStep = userData.verified ? USER_STEPS.DO_SIGN_IN : USER_STEPS.TWO_FACTOR;
        this.isNewAccount = false;
        this.loading = false;
        this.logStepNavigation(this.userStep);
      });
    } catch (error) {
      // Axios doesn't provide any status or code for this error
      const isNetworkError = axios.isAxiosError(error) && error?.message === 'Network Error';
      const userStep = isNetworkError ? USER_STEPS.CHECK_USERNAME : USER_STEPS.DO_SIGN_UP; // account does not exists

      runInAction(() => {
        this.username = normalizedEmail;
        this.userStep = userStep;
        this.logStepNavigation(userStep);
        this.isNewAccount = true;
        this.loading = false;

        if (isNetworkError) this.errors = this.errorService.wrapApiError(error);
      });
    }
  }

  @action
  public async doSignIn(password: string): Promise<void> {
    this.loading = true;
    try {
      const signInPayload: SignInRequestPayload = {
        username: this.username,
        password,
      };

      const userTokens = await this.restService.post<SignInRequestPayload, UserTokens>(
        endpoints.portaria.auth.sign_in,
        signInPayload,
        undefined,
        Infinity,
      );

      const { authorizationToken, refreshToken, identityToken } = userTokens;
      const { authorizationType, refreshHeader, identityHeader } = userTokens;
      const { username } = userTokens;

      // persist the refresh data into the secure storage
      await Promise.all([
        this.storageService.persistData(STORAGE_KEYS.USERNAME, username),
        this.storageService.persistData(STORAGE_KEYS.REFRESH_TOKEN, refreshToken),
      ]);

      this.analyticsService.setUser(username);

      runInAction(() => {
        // clean up cached data
        this.password = '';
        this.isNewAccount = false;

        this.username = username;
        this.authTokens = { authorizationToken, refreshToken, identityToken };
        this.authMetaData = { authorizationType, refreshHeader, identityHeader };
        this.userStep = USER_STEPS.AUTO_LOGON;
        this.logStepNavigation(USER_STEPS.AUTO_LOGON);
        // clear auth modal
        this.showAuthModal = false;
        this.loading = false;
      });

      this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.AUTH_SIGNIN, { method: 'email', tick: '1' }); // Google
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async createNewAccount(userAttributes: UserAttributes): Promise<void> {
    this.loading = true;
    try {
      const user: User = {
        username: this.username,
        ...userAttributes,
      };

      await this.restService.post<User, SignUpResponsePayload>(
        endpoints.portaria.user.sign_up,
        user,
        undefined,
        Infinity,
      );

      runInAction(() => {
        this.password = userAttributes.password ?? '';
        this.userStep = USER_STEPS.TWO_FACTOR;
        this.logStepNavigation(USER_STEPS.TWO_FACTOR);
        this.loading = false;
      });

      this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.AUTH_SIGNUP, { method: 'Email', tick: '1' }); // Google
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async confirmAccount(confirmationCode: string): Promise<void> {
    this.loading = true;
    try {
      const confirmation: ConfirmAccountRequestPayload = {
        username: this.username,
        token: confirmationCode,
      };

      await this.restService.post<ConfirmAccountRequestPayload, string>(
        endpoints.portaria.user.sign_up_verify,
        confirmation,
        undefined,
        Infinity,
      );

      // auto logon
      if (this.isNewAccount) {
        await this.doSignIn(this.password);
      } else {
        runInAction(() => {
          this.userStep = USER_STEPS.DO_SIGN_IN;
          this.logStepNavigation(USER_STEPS.DO_SIGN_IN);
        });
      }

      runInAction(() => {
        this.loading = false;
      });
      this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.AUTH_VERIFY, {
        af_registration_method: 'email',
        userId: confirmation.username,
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async refreshUserTokens(): Promise<void> {
    try {
      const [persistedUsername, persistedRefreshToken] = await Promise.all([
        this.storageService.retrieveData<string>(STORAGE_KEYS.USERNAME),
        this.storageService.retrieveData<string>(STORAGE_KEYS.REFRESH_TOKEN),
      ]);

      if (!persistedUsername || !persistedRefreshToken) {
        return runInAction(() => {
          // finished bootstrapping, no user
          this.bootstrappingUserTokens = false;
        });
      }

      const userTokens = await this.restService.post<RefreshSessionPayload, UserTokens>(
        endpoints.portaria.auth.refresh,
        { username: persistedUsername, refreshToken: persistedRefreshToken },
        undefined,
        Infinity,
      );

      const { authorizationToken, refreshToken, identityToken } = userTokens;
      const { authorizationType, refreshHeader, identityHeader } = userTokens;

      // persist just the new data, do not change the username
      await Promise.all([
        this.storageService.persistData(STORAGE_KEYS.USERNAME, persistedUsername),
        this.storageService.persistData(STORAGE_KEYS.REFRESH_TOKEN, refreshToken),
      ]);

      // send the user refresh metrics
      this.analyticsService.setUser(persistedUsername);
      // this.analyticsService.trackEvent(AnalyticsEvents.AUTH_REFRESHTOKEN, { userId: persistedUsername });

      return runInAction(() => {
        this.username = persistedUsername;
        this.authTokens = { authorizationToken, refreshToken, identityToken };
        this.authMetaData = { authorizationType, refreshHeader, identityHeader };
        this.userStep = USER_STEPS.DO_SIGN_OUT;
        // bootstrapped once already
        this.bootstrappingUserTokens = false;
        // clear auth modal
        this.showAuthModal = false;
      });
    } catch (error) {
      return runInAction(() => {
        this.bootstrappingUserTokens = false;
        this.userStep = USER_STEPS.CHECK_USERNAME;
        this.logStepNavigation(USER_STEPS.CHECK_USERNAME);
        this.errors = this.errorService.wrapApiError(error);
      });
    }
  }

  @action
  public async resendVerificationCode(): Promise<void> {
    this.loading = true;
    try {
      const payload: BaseUser = { username: this.username };

      await this.restService.post<BaseUser, VerificationCodePayload>(
        endpoints.portaria.user.resend_verification,
        payload,
        undefined,
        Infinity,
      );

      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async doSignOut(): Promise<void> {
    this.loading = true;
    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.portaria.user.sign_out,
        pathParams: { [PATH_PARAMS.USER_ID]: this.username },
      };

      // currently no usage for the response
      await this.restService.post<void, SignOutResponsePayload>(
        urlEndpoint,
        undefined,
        this.buildAuthHeaders(),
        Infinity,
      );

      await Promise.all([
        this.storageService.deleteData(STORAGE_KEYS.USERNAME),
        this.storageService.deleteData(STORAGE_KEYS.REFRESH_TOKEN),
      ]);

      runInAction(() => {
        this.username = '';
        this.authTokens = undefined;
        this.authMetaData = undefined;
        this.userStep = USER_STEPS.CHECK_USERNAME;
        this.logStepNavigation(USER_STEPS.CHECK_USERNAME);
        this.loading = false;
      });
      this.analyticsService.trackEvent(AnalyticsEvents.AUTH_SIGNOUT, { tick: '1' });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async requestForgotPasswordCode(): Promise<void> {
    this.loading = true;
    try {
      const payload: BaseUser = { username: this.username };

      await this.restService.post<BaseUser, VerificationCodePayload>(
        endpoints.portaria.auth.forgot_password,
        payload,
        undefined,
        Infinity,
      );

      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async recoverLostAccount(data: Omit<RecoverAccountRequestPayload, 'username'>): Promise<void> {
    this.loading = true;
    try {
      const recoverPayload: RecoverAccountRequestPayload = {
        username: this.username,
        ...data,
      };

      await this.restService.post<RecoverAccountRequestPayload, string>(
        endpoints.portaria.auth.forgot_password_verify,
        recoverPayload,
        undefined,
        Infinity,
      );

      // auto logon
      await this.doSignIn(data.password);

      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public buildAuthHeaders(): AuthHeaders {
    if (!this.authTokens || !this.authMetaData) {
      return { Authorization: undefined };
    }

    const { authorizationToken, identityToken } = this.authTokens ?? {};
    const { authorizationType, identityHeader } = this.authMetaData ?? {};

    return {
      Authorization: `${authorizationType} ${authorizationToken}`,
      [identityHeader]: identityToken,
    } as const;
  }

  @computed
  public get haveAuthorizationData(): boolean {
    return isNotEmpty(this.authTokens) && isNotEmpty(this.authMetaData);
  }
}
