import * as amplitude from '@amplitude/analytics-react-native';
import firebase, { type ReactNativeFirebase } from '@react-native-firebase/app';
import rudderStack from '@rudderstack/rudder-sdk-react-native';
import { activateKeepAwakeAsync } from 'expo-keep-awake';
import * as Notifications from 'expo-notifications';
import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';
import { inject, injectable, postConstruct } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Platform } from 'react-native';
import appsFlyer from 'react-native-appsflyer';
import * as facebook from 'react-native-fbsdk-next';
import mobileAds from 'react-native-google-mobile-ads';

import { STORE_TYPES, VALUE_TYPES, type ServiceFlags } from '@ioupie/shared/constants';
import { NavigationStore } from './navigation.store';

@injectable()
export class BootstrapStore {
  @observable public appReady: boolean = false;

  public constructor(
    @inject(VALUE_TYPES.SERVICE_FLAGS) private readonly serviceFlags: ServiceFlags,
    // quick hack, initialize apps flyer deep linking before initializing the sdk
    @inject(STORE_TYPES.NAVIGATION) private readonly navigationStore: NavigationStore,
  ) {}

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public async loadApplication(): Promise<void> {
    try {
      if (__DEV__ && Platform.OS !== 'web') {
        await activateKeepAwakeAsync();
      }

      Notifications.setNotificationHandler({ handleNotification: async () => this.notificationHandler() });
      // do not wait
      // - all of them should be wrapped into a try catch already
      // - possibly the reason why android is slow to start
      this.initializeAnalytics();

      runInAction(() => {
        this.appReady = true;
      });
    } catch (error) {
      console.log('Bootstrap failed', error);
      runInAction(() => {
        // some operations won't work on web mode anyways, so check if should load the app....
        this.appReady = Platform.OS === 'web';
        // if it crashes to load... it crashes...
      });
    }
  }

  @action
  private async initializeAnalytics() {
    try {
      const granted = await this.tryFetchTrackingPermissions();

      if (!granted) {
        return Promise.resolve();
      }

      await Promise.all([
        this.initFirebase(),
        this.initAppsFlyer(),
        this.initFacebook(),
        this.initAmplitude(),
        this.initRudderStack(),
        this.initGoogleAds(),
      ] as const);
    } catch (error) {
      console.log('Could not initialize application analytics', error);
    }
  }

  @action
  private async tryFetchTrackingPermissions() {
    try {
      // dynamically import to not crash web bundles
      // const { requestTrackingPermissionsAsync } = await import('expo-tracking-transparency');
      const { granted } = await requestTrackingPermissionsAsync();

      return granted;
    } catch (error) {
      // expo native module for transparency is broken for web
      return Platform.OS === 'web';
    }
  }

  @action
  private async notificationHandler(): Promise<Notifications.NotificationBehavior> {
    return Promise.resolve({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    });
  }

  @action
  private async initFirebase(): Promise<void> {
    if (!this.serviceFlags.analytics.firebase.enable) {
      return Promise.resolve();
    }

    try {
      if (firebase.apps.length > 0) {
        // already initialized, no need to bootstrap
        return;
      }

      await firebase.initializeApp(this.firebaseConfiguration);
    } catch (error) {
      console.log('Could not initialize Firebase SDK', error);
    }
  }

  @action
  private async initAppsFlyer(): Promise<void> {
    if (!this.serviceFlags.analytics.appsFlyer.enable) {
      return Promise.resolve();
    }

    try {
      appsFlyer.enableTCFDataCollection(true);
      await appsFlyer.initSdk({
        isDebug: __DEV__,
        devKey: 'L6xnAm2NPVsK6sycczxS6f',
        appId: 'id1495466238', // iOS only
        onInstallConversionDataListener: true,
        onDeepLinkListener: true,
        timeToWaitForATTUserAuthorization: 30,
      });
    } catch (error) {
      console.log('Could not initialize AppsFlyer SDK', error);
    }
  }

  @action
  private async initFacebook(): Promise<void> {
    if (!this.serviceFlags.analytics.facebook.enable) {
      return Promise.resolve();
    }

    try {
      facebook.Settings.setAppID('440055891173593');
      facebook.Settings.setAppName('ioupie');
      facebook.Settings.setClientToken('044c1648e24ce0927efc3f127bccfe4b');
      facebook.Settings.setAdvertiserIDCollectionEnabled(true);
      facebook.Settings.setAutoLogAppEventsEnabled(true);
      await facebook.Settings.setAdvertiserTrackingEnabled(true);
      facebook.Settings.initializeSDK();
    } catch (error) {
      console.log('Could not initialize Facebook SDK', error);
    }
  }

  @action
  private async initRudderStack(): Promise<void> {
    if (!this.serviceFlags.analytics.rudderStack.enable) {
      return Promise.resolve();
    }

    try {
      await rudderStack.setup('2ZVbZiIijRq0GpUpuWhmjwYUURX', {
        dataPlaneUrl: 'https://ioupietecvdkbu.dataplane.rudderstack.com',
        trackAppLifecycleEvents: true,
        recordScreenViews: true,
        collectDeviceId: true,
        autoCollectAdvertId: true,
      });
    } catch (error) {
      console.log('Could not initialize RudderStack SDK', error);
    }
  }

  @action
  private async initAmplitude(): Promise<void> {
    if (!this.serviceFlags.analytics.amplitude.enable) {
      return Promise.resolve();
    }

    try {
      await amplitude.init('46f31b2d7e63e925f0f49d80ae52b7e1', undefined, {
        defaultTracking: true,
        trackingSessionEvents: true,
        trackingOptions: { appSetId: true, adid: true, idfv: true, ipAddress: true },
      }).promise;
    } catch (error) {
      console.log('Could not initialize Amplitude SDK', error);
    }
  }

  @action
  private async initGoogleAds(): Promise<void> {
    if (!this.serviceFlags.analytics.googleAds.enable) {
      return Promise.resolve();
    }

    try {
      await mobileAds().initialize();
    } catch (error) {
      console.log('Could not initialize Google Ads', error);
    }
  }

  @computed
  private get firebaseConfiguration(): ReactNativeFirebase.FirebaseAppOptions {
    switch (Platform.OS) {
      case 'web':
        return {
          apiKey: 'AIzaSyBYFY2TDNmuBCeAdEt7L4C5gWLeLv-KZKk',
          authDomain: 'ioupie-mobile-app-expo.firebaseapp.com',
          databaseURL: 'https://ioupie-mobile-app-expo.firebaseio.com',
          projectId: 'ioupie-mobile-app-expo',
          storageBucket: 'ioupie-mobile-app-expo.appspot.com',
          messagingSenderId: '349829330475',
          appId: '1:349829330475:web:efad375b5fb93052e85c6e',
          measurementId: 'G-RD8R39FK19',
        };
      case 'android':
        return {
          apiKey: 'AIzaSyBW6WRabwIcOp5bE-tVz67cxnSy00h5mys',
          authDomain: 'ioupie-mobile-app-expo.firebaseapp.com',
          databaseURL: 'https://ioupie-mobile-app-expo.firebaseio.com',
          projectId: 'ioupie-mobile-app-expo',
          storageBucket: 'ioupie-mobile-app-expo.appspot.com',
          messagingSenderId: '349829330475',
          appId: '1:349829330475:android:1a6c22ff5b5ea419e85c6e',
          clientId: '349829330475-ut5orvohg8fvkmb5i43b35o74pe4lb0n.apps.googleusercontent.com',
        };
      case 'ios':
        return {
          apiKey: 'AIzaSyBsIDSmg_lgmz7akVCPl5WsbDAtnXXAG5E',
          authDomain: 'ioupie-mobile-app-expo.firebaseapp.com',
          databaseURL: 'https://ioupie-mobile-app-expo.firebaseio.com',
          projectId: 'ioupie-mobile-app-expo',
          storageBucket: 'ioupie-mobile-app-expo.appspot.com',
          messagingSenderId: '349829330475',
          appId: '1:349829330475:ios:cccbfbd2921943a3e85c6e',
          clientId: '349829330475-tcmvvdhkv78soqpukq74jkvtheqt471u.apps.googleusercontent.com',
          androidClientId: '349829330475-ut5orvohg8fvkmb5i43b35o74pe4lb0n.apps.googleusercontent.com',
        };
      default:
        throw new Error(`Firebase platform ${Platform.OS} not supported`);
    }
  }
}
