import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createMaterialBottomTabNavigator,
  MaterialBottomTabNavigationOptions,
} from 'react-native-paper/react-navigation';

import { TabIcon } from '@ioupie/components/gallery';
import { colors, routes } from '@ioupie/shared/constants';

import { useTheme } from '@ioupie/hooks';
import { styles } from './home.styles';
import { Delivery, Lockers, MyOrders } from './tabs';

const Tabs = createMaterialBottomTabNavigator();

/**
 * @function HomePage
 */
export default memo(() => {
  const [t] = useTranslation();
  const { mode } = useTheme();

  const deliveryOptions: MaterialBottomTabNavigationOptions = {
    tabBarIcon: ({ color, focused }) => {
      return <TabIcon name="truck-outline" color={color} size={25} />;
    },
    tabBarLabel: t('pages.home.delivery'),
  };

  const lockersOptions: MaterialBottomTabNavigationOptions = {
    tabBarIcon: ({ color, focused }) => {
      return <TabIcon name="locker" color={color} size={25} />;
    },
    tabBarLabel: t('pages.home.lockers'),
    tabBarBadge: true,
  };

  const myOrdersOptions: MaterialBottomTabNavigationOptions = {
    tabBarIcon: ({ color, focused }) => {
      return <TabIcon name="file-multiple-outline" color={color} size={24} />;
    },
    tabBarLabel: t('pages.home.my-orders'),
  };

  return (
    <Tabs.Navigator
      backBehavior="none"
      activeColor={mode === 'dark' ? colors.dark.tertiary : colors.light.tertiary}
      inactiveColor={mode === 'dark' ? colors.dark.text : colors.light.text}
      initialRouteName={routes.tabs.home.delivery}
      barStyle={styles(mode).tabsOptions}>
      <Tabs.Screen name={routes.tabs.home.delivery} component={Delivery} options={deliveryOptions} />
      <Tabs.Screen name={routes.tabs.home.lockers} component={Lockers} options={lockersOptions} />
      <Tabs.Screen name={routes.tabs.home.my_orders} component={MyOrders} options={myOrdersOptions} />
    </Tabs.Navigator>
  );
});
