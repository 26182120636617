export default {
  enableTCFDataCollection: (enable: boolean) => undefined,
  onDeepLink: async () => async () => Promise.resolve(),
  initSdk: async () => Promise.resolve(),
  setUserEmails: async () => Promise.resolve(),
  setCustomerUserId: (userId: string, successC?: CallableFunction) => {
    if (successC) successC();
  },
  getAppsFlyerUID: (callback: (error?: unknown, uid?: string) => void) => {
    callback(new Error('AppsFlyer does not have a device id when running in web'), undefined);
  },
  logEvent: async (eventName: string, eventValue: object) => {},
};

export const AF_EMAIL_CRYPT_TYPE = {
  NONE: 0,
  SHA256: 1,
};
