import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { OrdersStore } from '@ioupie/stores';

export const useOrdersStore = (): OrdersStore => {
  const orders = useInjection<OrdersStore>(STORE_TYPES.ORDERS);
  return useLocalObservable(() => orders);
};
