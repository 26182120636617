import { BUSINESS_PARTNER_STATUS, BUSINESS_SEGMENTS_OPTIONS, CREATE_ORDER_VALIDATION_OPTIONS } from '../constants';
import { ProductPrice } from './products.models';

export enum PLAN {
  PREMIUM = 'Premium',
  INTERMEDIARY = 'Intermediário',
  BASIC = 'Básico',
}

export declare type Rating = {
  readonly source: string;
  readonly value: number;
};

export declare type DeliveryFees = {
  readonly from: number;
  readonly to: number;
};

export declare type ProviderMetadata = {
  readonly validationType?: CREATE_ORDER_VALIDATION_OPTIONS;
  readonly deliveryFees: DeliveryFees;
  readonly description: string;
  readonly dropOff: ServiceProviderDropOffMetadata;
  readonly message: string;
  readonly minValue: ProductPrice;
  readonly minProducts: number;
  readonly minimumDays: number;
  readonly rating: Rating;
  readonly plan: PLAN;
};

export declare type ServiceProviderDropOffMetadata = {
  readonly dropOffPoint: boolean;
  readonly parentCompany?: string;
  readonly physicalAddressId: number;
  readonly physicalAddress?: Record<string, string>;
};

export declare type Provider = {
  readonly id: string;
  readonly address: string;
  readonly creationTimestamp?: string;
  readonly name?: string;
  readonly corporateName?: string;
  readonly image: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly eligible: boolean;
  readonly status: BUSINESS_PARTNER_STATUS;
  readonly dist: number;
  readonly metadata?: Partial<ProviderMetadata>;
  readonly segment?: BUSINESS_SEGMENTS_OPTIONS;
  readonly taxpayerId?: string;
  readonly highlight?: boolean;
};

export declare type SegmentProviders = {
  readonly segment: BUSINESS_SEGMENTS_OPTIONS;
  readonly providers: readonly Provider[];
  readonly image: string;
};
