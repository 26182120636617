import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { AddressStore } from '@ioupie/stores';

export const useAddressStore = (): AddressStore => {
  const address = useInjection<AddressStore>(STORE_TYPES.ADDRESS);
  return useLocalObservable(() => address);
};
