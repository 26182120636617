import type { NavigationState } from '@react-navigation/native';
import { createNavigationContainerRef } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { inject, injectable, postConstruct, preDestroy } from 'inversify';
import { action, computed, makeObservable, observable, when } from 'mobx';
import queryString, { ParsedQuery } from 'query-string';
import { Platform } from 'react-native';
import appsFlyer from 'react-native-appsflyer';
import urlParse from 'url-parse';

import { type AnalyticsService } from '@ioupie/services';
import {
  ADDRESS_ROUTES,
  AUTH_ROUTES,
  AnalyticsEvents,
  BUSINESS_AREA_OPTIONS,
  BUSINESS_SEGMENTS_OPTIONS,
  HELP_ROUTES,
  ORDER_ROUTES,
  PAYMENT_ROUTES,
  PROFILE_ROUTES,
  ROUTE_STACKS,
  SERVICE_TYPES,
  SETTINGS_ROUTES,
  STORE_TYPES,
  routes,
} from '@ioupie/shared/constants';
import type { DeepLinkingNavigation, NavigationCommand, NavigationValues, Optional } from '@ioupie/shared/models';
import { isEnumOf, isString, safeObjectLookup } from '@ioupie/shared/utils';

import { LockersStore } from './lockers.store';
import { ShopsStore } from './shops.store';

@injectable()
export class NavigationStore {
  private static readonly APPSFLYER_LINK_PREFIX = '/oR1b';
  private static readonly EXPO_LINK_PREFIX = '/linking';

  public constructor(
    @inject(STORE_TYPES.SHOPS)
    private readonly shopsStore: ShopsStore,
    @inject(STORE_TYPES.LOCKERS)
    private readonly lockersStore: LockersStore,
    @inject(SERVICE_TYPES.ANALYTICS.COMPOSITE)
    private readonly analyticsService: AnalyticsService,
  ) {}

  @observable public currentNavigationState?: NavigationState = undefined;

  @observable public appsFlyerConversionListener?: () => void = undefined;
  @observable public appsFlyerLinkingListener?: () => void = undefined;
  @observable public expoLinkingListener?: () => void = undefined;

  // stack
  @observable public readonly isRouteStack = isEnumOf(ROUTE_STACKS);
  // screens
  @observable public readonly isOrderRoute = isEnumOf(ORDER_ROUTES);
  @observable public readonly isPaymentRoute = isEnumOf(PAYMENT_ROUTES);
  @observable public readonly isAuthRoute = isEnumOf(AUTH_ROUTES);
  @observable public readonly isSettingsRoute = isEnumOf(SETTINGS_ROUTES);
  @observable public readonly isHelpRoute = isEnumOf(HELP_ROUTES);
  @observable public readonly isProfileRoute = isEnumOf(PROFILE_ROUTES);
  @observable public readonly isAddressRoute = isEnumOf(ADDRESS_ROUTES);

  public readonly screensCheckTable = {
    [ROUTE_STACKS.address]: this.isAddressRoute,
    [ROUTE_STACKS.auth]: this.isAuthRoute,
    [ROUTE_STACKS.help]: this.isHelpRoute,
    [ROUTE_STACKS.orders]: this.isOrderRoute,
    [ROUTE_STACKS.payment]: this.isPaymentRoute,
    [ROUTE_STACKS.profile]: this.isProfileRoute,
    [ROUTE_STACKS.settings]: this.isSettingsRoute,
  } as const;

  @observable public readonly navigationRef = createNavigationContainerRef<NavigationValues>();

  @postConstruct()
  public init(): void {
    makeObservable(this);

    try {
      this.appsFlyerConversionListener = this.listenAppsFlyerConversions();
    } catch (error) {
      this.appsFlyerConversionListener = () => undefined;
      console.error('could not apps flyer conversions...');
    }

    try {
      this.appsFlyerLinkingListener = this.listenAppsFlyerDeepLinking();
    } catch (error) {
      this.appsFlyerLinkingListener = () => undefined;
      console.error('could not apps flyer deep linking...');
    }

    try {
      this.expoLinkingListener = this.listenExpoLinkingDeepLinking();
    } catch (error) {
      this.expoLinkingListener = () => undefined;
      console.error('could not expo deep linking...');
    }
  }

  @preDestroy()
  public destroy(): void {
    if (this.appsFlyerConversionListener) {
      this.appsFlyerConversionListener();
    }

    if (this.appsFlyerLinkingListener) {
      this.appsFlyerLinkingListener();
    }

    if (this.expoLinkingListener) {
      this.expoLinkingListener();
    }
  }

  @action
  private listenAppsFlyerConversions(): () => void {
    const appsFlyerConversionRemove = appsFlyer.onInstallConversionData(async (data) => {
      this.analyticsService.trackEvent(AnalyticsEvents.APP_CONVERSION, data);
    });

    return action(() => appsFlyerConversionRemove());
  }

  @action
  private listenAppsFlyerDeepLinking(): () => void {
    // Configure Deep Link listener
    // (should be done BEFORE the call to initSdk)
    const appsFlyerLinkingRemove = appsFlyer.onDeepLink(async (res) => {
      if (!res?.data || res.deepLinkStatus === 'NOT_FOUND' || res.deepLinkStatus === 'Error') {
        console.error(`[AppsFlyer] Deep link configuration failed: ${res?.deepLinkStatus}`);
        return;
      }

      const { data } = res ?? {};
      const { link = '' } = data ?? {};

      if (!link.includes(NavigationStore.APPSFLYER_LINK_PREFIX)) {
        return;
      } else {
        console.log('[AppsFlyer] Deep linking request received');
      }

      const linkingNav = this.checkAndParsePageRoute(data);

      if (!linkingNav) {
        console.error('[AppsFlyer] Failed logging deep linking failure');
        return;
      }

      try {
        // navigate
        await this.deepLinkingNavigation(linkingNav);
      } catch (error) {
        console.error('[AppsFlyer] Failed while waiting for navigation', error);
      }
    });

    return action(() => appsFlyerLinkingRemove());
  }

  @action
  private listenExpoLinkingDeepLinking(): () => void {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const listener = Linking.addEventListener('url', async (event) => {
      const { url = '' } = event ?? {};

      if (!url) {
        console.error(`[Expo] Deep link configuration failed: ${url}`);
        return;
      }

      if (!url.includes(NavigationStore.EXPO_LINK_PREFIX)) {
        return;
      } else {
        console.log('[Expo] Deep linking request received');
      }

      const { query = '' } = urlParse(url);
      const deepLinkData = queryString.parse(query);

      const linkingNav = this.checkAndParsePageRoute(deepLinkData);

      if (!linkingNav) {
        console.error('[Expo] Failed logging deep linking failure');
        return;
      }

      try {
        // navigate
        await this.deepLinkingNavigation(linkingNav);
      } catch (error) {
        console.error('[Expo] Failed while waiting for navigation', error);
      }
    });

    return action(() => listener.remove());
  }

  @computed
  public get navigationReady(): boolean {
    if (Platform.OS === 'web') {
      return true;
    } else {
      return this.navigationRef.isReady();
    }
  }

  @computed
  public get navigationState(): Optional<NavigationState> {
    return this.currentNavigationState;
  }

  @action
  public updateNavigationState(navigationState?: NavigationState): void {
    this.currentNavigationState = navigationState;
  }

  @action
  public dispatchNavigation(command: NavigationCommand): void {
    if (!this.navigationReady) return;

    const { screen, stack, params, loggingMetaData = {} } = command ?? {};

    this.navigationRef.navigate(stack, { screen, params });

    try {
      // dispatch and leave it
      this.sendNavigationAnalytics(stack, screen, loggingMetaData);
    } catch (error) {
      console.log('Failed logging navigation event');
    }
  }

  @action
  private clearBrowserUrl() {
    if (Platform.OS === 'web') {
      window.history.replaceState({}, '', '/');
    }
  }

  @action
  private async deepLinkingNavigation(linkingNav?: Optional<DeepLinkingNavigation>): Promise<void> {
    if (!linkingNav) {
      return;
    }

    // if running in web, this will prevent the navigation to enter in an infinite loop
    this.clearBrowserUrl();

    const { stack, screen, provider, locker, params = {} } = linkingNav;
    const { segment = BUSINESS_SEGMENTS_OPTIONS.LAUNDRY, business = BUSINESS_AREA_OPTIONS.DELIVERY } = params;

    // delivery check - if no provider, just log error
    if (
      screen === routes.pages.orders.catalog &&
      (!provider || business.toLowerCase() !== BUSINESS_AREA_OPTIONS.DELIVERY.toLowerCase())
    ) {
      this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.FAILURE_LINKING, linkingNav);
      return;
    }

    // lockers check - if no locker, just log error
    if (
      screen === routes.pages.orders.providers &&
      (!locker || business.toLowerCase() !== BUSINESS_AREA_OPTIONS.LOCKER.toLowerCase())
    ) {
      this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.FAILURE_LINKING, linkingNav);
      return;
    }

    // wait for the navigation to be ready
    await when(() => this.navigationReady);

    if (stack === routes.stacks.orders) {
      if (provider && screen === routes.pages.orders.catalog) {
        this.shopsStore.selectShopProvider(provider);
      }

      if (locker && screen === routes.pages.orders.providers) {
        this.lockersStore.selectLockerProvider(locker);
      }

      // set shop data
      this.shopsStore.setShopSegment(segment.toUpperCase() as BUSINESS_SEGMENTS_OPTIONS);
      this.shopsStore.setShopBusinessArea(business.toUpperCase() as BUSINESS_AREA_OPTIONS);
    }

    // navigate to the received link
    this.dispatchNavigation({ stack, screen } as const);
    // log the linking
    this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.DEEP_LINKING, linkingNav);
  }

  @action
  public dispatchGoBack() {
    this.navigationRef.goBack();

    /**
     * still a work in progress ...
     * later on we will use this to log the navigate back event
     * logging navigate back only is useless, we need to check the previous
     * stack/screen and send it to analytics.
     */
  }

  @action
  public sendNavigationAnalytics(stack: string, screen?: string, loggingMetaData?: Record<string, string>): void {
    this.analyticsService.setScreen(`${stack}/${screen ?? ''}`, loggingMetaData);
  }

  @action
  public checkAndParsePageRoute(props: Optional<ParsedQuery>): DeepLinkingNavigation | undefined {
    // eslint-disable-next-line no-null/no-null
    if (props === undefined || props === null) {
      return undefined;
    }

    const { ioupie_stack, ioupie_screen, ioupie_provider, ioupie_locker, ...params } = props ?? {};

    if (!ioupie_stack || !this.isRouteStack(ioupie_stack)) {
      return undefined;
    }

    const checker = safeObjectLookup(this.screensCheckTable, ioupie_stack);

    if (!ioupie_screen || !checker || !checker(ioupie_screen)) {
      return undefined;
    }

    // eslint-disable-next-line no-null/no-null
    if (ioupie_provider !== undefined && ioupie_provider !== null && typeof ioupie_provider !== 'string') {
      return undefined;
    }

    // eslint-disable-next-line no-null/no-null
    if (ioupie_locker !== undefined && ioupie_locker !== null && typeof ioupie_locker !== 'string') {
      return undefined;
    }

    const parsedParams = Object.entries(params)
      .filter((entry): entry is [string, string] => isString(entry[1]))
      .reduce((acc, entry) => {
        return { ...acc, [entry[0]]: entry[1] };
      }, {});

    // has been validated
    return {
      stack: ioupie_stack,
      screen: ioupie_screen,
      provider: ioupie_provider,
      locker: ioupie_locker,
      params: parsedParams,
    } as const;
  }
}
