import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { AttentionBox, ErrorSnackbar } from '@ioupie/components/custom';
import { ScrollView } from '@ioupie/components/layout';
import { LinkText, Text } from '@ioupie/components/typography';
import { AuthModal } from '@ioupie/containers/auth';
import { useLockersStore, useOrdersStore, usePaymentStore, useShopsStore } from '@ioupie/hooks';
import {
  AnalyticsEvents,
  BUSINESS_AREA_OPTIONS,
  BUSINESS_SEGMENTS_OPTIONS,
  ORDERING_STEPS,
  PAYMENT_METHOD_TYPES,
} from '@ioupie/shared/constants';

import { Amount } from '../amount';
import { Payment } from '../payment';
import { PaymentMethodForm } from '../payment/payment.models';
import { Summary } from '../summary';
import * as S from './payment-step.styles';

/**
 * @function PaymentContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();
  const lockersStore = useLockersStore();
  const ordersStore = useOrdersStore();
  const paymentStore = usePaymentStore();

  const { lockerProvider } = lockersStore ?? {};

  const { selectedPaymentMethod } = paymentStore;
  const { issuer = '' } = selectedPaymentMethod ?? {};

  const paymentSchema: yup.SchemaOf<PaymentMethodForm> = yup.object().shape({
    method: yup
      .string()
      .required(t('containers.orders.ordering.payment-step.method-errors.required'))
      .min(1, ({ min }) => t('containers.orders.ordering.payment-step.method-errors.min', { min })),
  });

  const formMethods = useForm<PaymentMethodForm>({
    defaultValues: {
      method: '',
    },
    resolver: yupResolver(paymentSchema),
  });
  const {
    watch,
    formState: { isValid },
  } = formMethods;

  const invalidCreditCard = !isValid || issuer === undefined || issuer.length === 0;

  const handleNextButton = () => {
    ordersStore.setOrderPaymentPayload({
      _pid: watch('method'),
      _cvv: '',
      method: paymentStore.selectedPaymentType,
    });
    ordersStore.changeOrderingStep(ORDERING_STEPS.SUMMARY);
  };

  const attentionText = (
    <Text>
      {t('containers.orders.ordering.payment-step.payment-warning-label')}
      <LinkText
        text={t('containers.orders.ordering.payment-step.more-info')}
        link="https://www.ioupie.com.br/faq/pagamento-via-app/"
      />
    </Text>
  );

  useEffect(() => {
    ordersStore.recordInAppEventForJourney(AnalyticsEvents.CHECKOUT_INITIATED_PAYMENT_SELECTION);

    if (shopsStore.shopProvider?.segment === BUSINESS_SEGMENTS_OPTIONS.GOODS) {
      const isLocker = shopsStore.shopBusinessArea === BUSINESS_AREA_OPTIONS.LOCKER;
      const type = isLocker ? BUSINESS_AREA_OPTIONS.LOCKER : BUSINESS_AREA_OPTIONS.DELIVERY;
      // eslint-disable-next-line no-void
      void ordersStore.fetchScheduleDates(type, isLocker ? lockerProvider?.id : undefined);

      // eslint-disable-next-line functional/immutable-data
      const firstDate = Object.keys(ordersStore.nextPickupDatesAvailable ?? {}).sort()[0];
      ordersStore.setPickUpOrderDate(firstDate);
    }
  }, []);

  useEffect(() => {
    if (shopsStore.shopProvider?.segment === BUSINESS_SEGMENTS_OPTIONS.GOODS) {
      // eslint-disable-next-line functional/immutable-data
      const firstDate = Object.keys(ordersStore.nextPickupDatesAvailable ?? {}).sort()[0];
      ordersStore.setPickUpOrderDate(firstDate);
    }
  }, [ordersStore.nextPickupDatesAvailable]);

  return (
    <Fragment>
      <ScrollView>
        <Summary />
        <Amount showCouponField />
        <S.Separator />
        <FormProvider {...formMethods}>
          <Payment readOnly={false} />
          {/* {paymentStore.selectedPaymentType === PAYMENT_METHOD_TYPES.CREDIT ? (
            <S.SecurePaymentContainer>
              <AttentionBox
                icon="credit-card-lock-outline"
                title={t('containers.orders.ordering.payment-step.payment-warning-title')}
                text={attentionText}
              />
            </S.SecurePaymentContainer>
          ) : undefined} */}
        </FormProvider>

        <S.NextButton
          disabled={invalidCreditCard && paymentStore.selectedPaymentType === PAYMENT_METHOD_TYPES.CREDIT}
          loading={ordersStore.loading}
          text={t('containers.orders.ordering.next-button')}
          onPress={handleNextButton}
        />
      </ScrollView>
      <ErrorSnackbar errors={shopsStore.errors} onDismiss={() => shopsStore.clearErrors()} />
      <AuthModal dismissable={false} />
    </Fragment>
  );
});
