import { LinearGradient } from 'expo-linear-gradient';
import { memo } from 'react';

import { useTheme } from '@ioupie/hooks';
import { colors } from '@ioupie/shared/constants';
import { ListItemProps } from './item.models';
import * as S from './item.styles';

/**
 * @function ListMessageComponent
 */
export default memo(
  ({
    body,
    left,
    right,
    top,
    bottom,
    showBorder = true,
    alignTop = false,
    highlight = false,
    onPress,
    onLongPress,
  }: ListItemProps) => {
    const theme = useTheme();

    const { primary: fadeColor } = theme.mode === 'light' ? colors.light : colors.dark;

    const highlightColors = highlight ? [fadeColor, 'transparent'] : ['transparent', 'transparent'];
    const highlightLocations = highlight ? [0, 0.5] : [0, 0];

    return (
      <LinearGradient
        style={{ borderRadius: 7, padding: 0, margin: 0 }}
        start={{ x: 0.0, y: 0.25 }}
        end={{ x: 0.5, y: 0.5 }}
        locations={highlightLocations}
        colors={highlightColors}>
        <S.ItemContainer showBorder={showBorder}>
          {top && <S.TopContent>{top}</S.TopContent>}
          <S.ItemTouchableArea disabled={!onPress && !onLongPress} onPress={onPress} onLongPress={onLongPress}>
            <S.ItemContent>
              {left && <S.LeftContent alignTop={alignTop}>{left}</S.LeftContent>}
              {body && <S.BodyContent>{body}</S.BodyContent>}
              {right && <S.RightContent>{right}</S.RightContent>}
            </S.ItemContent>
          </S.ItemTouchableArea>
          {bottom && <S.BottomContent>{bottom}</S.BottomContent>}
        </S.ItemContainer>
      </LinearGradient>
    );
  },
);
