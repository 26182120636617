import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { DeliveryStore } from '@ioupie/stores';

export const useDeliveryStore = (): DeliveryStore => {
  const delivery = useInjection<DeliveryStore>(STORE_TYPES.DELIVERY);
  return useLocalObservable(() => delivery);
};
