export const SERVICE_FLAGS = {
  analytics: {
    composite: { enable: true },
    firebase: { enable: true },
    facebook: { enable: true },
    appsFlyer: { enable: true },
    amplitude: { enable: true },
    rudderStack: { enable: true },
    googleAds: { enable: true },
  },
};

export type ServiceFlags = typeof SERVICE_FLAGS;
