import { inject, injectable } from 'inversify';
import * as facebook from 'react-native-fbsdk-next';

import { AnalyticsConstants, AnalyticsEvents, VALUE_TYPES, type ServiceFlags } from '@ioupie/shared/constants';
import { type UserProfile } from '@ioupie/shared/models';

import { type AnalyticsService } from './analytics.service';
import { BaseAnalyticsService } from './base-analytics.service';

@injectable()
export class FacebookAnalyticsService extends BaseAnalyticsService implements AnalyticsService {
  public constructor(@inject(VALUE_TYPES.SERVICE_FLAGS) private readonly serviceFlags: ServiceFlags) {
    super();
  }

  public async setUser(userId: string) {
    if (!this.serviceFlags.analytics.facebook.enable) {
      return Promise.resolve();
    }

    try {
      await new Promise((resolve, reject) => {
        try {
          resolve(facebook.AppEventsLogger.setUserID(userId));
        } catch (e) {
          reject(e);
        }
      });
    } catch (error) {
      console.error(`Facebook user [${userId}] error`, error);
    }
  }

  public async setUserProperties(userProps: UserProfile) {
    if (!this.serviceFlags.analytics.facebook.enable) {
      return Promise.resolve();
    }

    try {
      await new Promise((resolve, reject) => {
        try {
          resolve(
            facebook.AppEventsLogger.setUserData({
              firstName: userProps.name,
              lastName: userProps.familyName,
              email: userProps.email,
              dateOfBirth: userProps.birthDate,
              phone: userProps.phoneNumber,
            }),
          );
        } catch (e) {
          reject(e);
        }
      });
    } catch (error) {
      console.error(`Facebook user [${userProps?.subject}] properties error`, error);
    }
  }

  public async setScreen(screen: string, metaData: Record<string, string> = {}) {
    if (!this.serviceFlags.analytics.facebook.enable) {
      return Promise.resolve();
    }

    try {
      const screenView = this.prefixNativeEventName('screen_view');
      const eventPayload = this.buildScreenPayload(screen, metaData);

      await new Promise((resolve, reject) => {
        try {
          resolve(facebook.AppEventsLogger.logEvent(screenView, eventPayload));
        } catch (e) {
          reject(e);
        }
      });
    } catch (error) {
      console.error(`Facebook screen [${screen}] error`, error);
    }
  }

  public async trackEvent(event: AnalyticsEvents, eventData: Record<string, any> = {}) {
    if (!this.serviceFlags.analytics.facebook.enable) {
      return Promise.resolve();
    }

    try {
      const prefixedEventName = this.prefixEventName(AnalyticsConstants.Facebook[event]);

      await new Promise((resolve, reject) => {
        try {
          resolve(facebook.AppEventsLogger.logEvent(prefixedEventName, eventData));
        } catch (e) {
          reject(e);
        }
      });
    } catch (error) {
      console.error(`Facebook event [${event}] error`, error);
    }
  }

  public async trackUnprefixedEvent(event: AnalyticsEvents, eventData: Record<string, any> = {}) {
    if (!this.serviceFlags.analytics.facebook.enable) {
      return Promise.resolve();
    }

    try {
      const prefixedEventName = this.prefixNativeEventName(AnalyticsConstants.Facebook[event]);

      await new Promise((resolve, reject) => {
        try {
          resolve(facebook.AppEventsLogger.logEvent(prefixedEventName, eventData));
        } catch (e) {
          reject(e);
        }
      });
    } catch (error) {
      console.error(`Facebook native event [${event}] error`, error);
    }
  }
}
