import * as Sentry from '@sentry/react-native';
import { inject, injectable } from 'inversify';
import { isPresent } from 'ts-is-present';

import { AnalyticsEvents, SERVICE_TYPES, VALUE_TYPES, type ServiceFlags } from '@ioupie/shared/constants';
import { type UserProfile } from '@ioupie/shared/models';

import { AmplitudeAnalyticsService } from './amplitude-analytics.service';
import { type AnalyticsService } from './analytics.service';
import { AppsFlyerAnalyticsService } from './appsflyer-analytics.service';
import { BaseAnalyticsService } from './base-analytics.service';
import { FacebookAnalyticsService } from './facebook-analytics.service';
import { FirebaseAnalyticsService } from './firebase-analytics.service';
import { RudderStackAnalyticsService } from './rudderstack-analytics.service';

@injectable()
export class CompositeAnalyticsService extends BaseAnalyticsService implements AnalyticsService {
  public constructor(
    @inject(VALUE_TYPES.SERVICE_FLAGS) private readonly serviceFlags: ServiceFlags,
    @inject(SERVICE_TYPES.ANALYTICS.FIREBASE) private readonly firebaseAnalyticsService: FirebaseAnalyticsService,
    @inject(SERVICE_TYPES.ANALYTICS.FIREBASE) private readonly facebookAnalyticsService: FacebookAnalyticsService,
    @inject(SERVICE_TYPES.ANALYTICS.AMPLITUDE) private readonly amplitudeAnalyticsService: AmplitudeAnalyticsService,
    @inject(SERVICE_TYPES.ANALYTICS.APPS_FLYER) private readonly appsFlyerAnalyticsService: AppsFlyerAnalyticsService,
    @inject(SERVICE_TYPES.ANALYTICS.RUDDER_STACK)
    private readonly rudderStackAnalyticsService: RudderStackAnalyticsService,
  ) {
    super();
  }

  public get analyticsServices() {
    return [
      this.serviceFlags.analytics.firebase.enable ? this.firebaseAnalyticsService : undefined,
      this.serviceFlags.analytics.facebook.enable ? this.facebookAnalyticsService : undefined,
      this.serviceFlags.analytics.amplitude.enable ? this.amplitudeAnalyticsService : undefined,
      this.serviceFlags.analytics.appsFlyer.enable ? this.appsFlyerAnalyticsService : undefined,
      this.serviceFlags.analytics.rudderStack.enable ? this.rudderStackAnalyticsService : undefined,
    ].filter(isPresent);
  }

  public async setUser(userId: string) {
    if (!this.serviceFlags.analytics.composite.enable) {
      return Promise.resolve();
    }

    try {
      await Promise.all(this.analyticsServices.map((service) => service.setUser(userId)));
    } catch (error) {
      console.error(`Composite Analytics user [${userId}] error`, error);
    }

    try {
      Sentry.setUser({ id: userId });
    } catch (error) {
      console.error(`Error setting user ID [${userId}] in Sentry`, error);
    }
  }

  public async setUserProperties(userProps: UserProfile) {
    if (!this.serviceFlags.analytics.composite.enable) {
      return Promise.resolve();
    }

    try {
      await Promise.all(this.analyticsServices.map((service) => service.setUserProperties(userProps)));
    } catch (error) {
      console.error(`Composite Analytics user [${userProps?.subject}] properties error`, error);
    }

    try {
      Sentry.setUser({
        id: userProps?.subject,
        username: userProps?.username,
        email: userProps?.email,
        ip_address: '{{auto}}',
      });
    } catch (error) {
      console.error(`Error setting user properties [${userProps?.subject}] in Sentry`, error);
    }
  }

  public async setScreen(screen: string, metaData: Record<string, string> = {}) {
    if (!this.serviceFlags.analytics.composite.enable) {
      return Promise.resolve();
    }

    try {
      await Promise.all(this.analyticsServices.map((service) => service.setScreen(screen, metaData)));
    } catch (error) {
      console.error(`Composite Analytics screen [${screen}] error`, error);
    }
  }

  public async trackEvent(eventName: AnalyticsEvents, eventData: Record<string, any> = {}) {
    if (!this.serviceFlags.analytics.composite.enable || eventName === undefined) {
      return Promise.resolve();
    }

    try {
      await Promise.all(this.analyticsServices.map((service) => service.trackEvent(eventName, eventData)));
    } catch (error) {
      console.error(`Composite Analytics event [${eventName}] error`, error);
    }
  }

  /**
   * **ATTENTION:** Should only be used for native/recommended events. Will persist the events without
   * setting the "Prod_" prefix if the app is in production mode.
   * @param {string} eventName The key to be shown at analytics
   * @param {Record<string, any>} eventData Any metadata applied to the event
   * @returns {Promise<void>} Empty promise
   */
  public async trackUnprefixedEvent(eventName: AnalyticsEvents, eventData: Record<string, any> = {}): Promise<void> {
    if (!this.serviceFlags.analytics.composite.enable || eventName === undefined) {
      return Promise.resolve();
    }

    try {
      await Promise.all(this.analyticsServices.map((service) => service.trackUnprefixedEvent(eventName, eventData)));
    } catch (error) {
      console.error(`Analytics native event [${eventName}] error`, error);
    }
  }
}
