import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { PaymentStore } from '@ioupie/stores';

export const usePaymentStore = (): PaymentStore => {
  const payment = useInjection<PaymentStore>(STORE_TYPES.PAYMENT);
  return useLocalObservable(() => payment);
};
