import { memo } from 'react';

import { BodyView } from '@ioupie/components/layout';
import { AuthModal } from '@ioupie/containers/auth';
import { LockersList } from '@ioupie/containers/lockers';
import {
  useCurrentRouteFocus,
  useHeaderStore,
  useShopsStore,
  useLockersStore,
  useNavigationStore,
} from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';
import { Platform } from 'react-native';

/**
 * @function LockersTab
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const shopsStore = useShopsStore();
  const lockersStore = useLockersStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.tabs.home.lockers, () => {
    headerStore.allowNavigateBack(false);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.home.lockers-title');
    headerStore.changeSubtitle('');

    shopsStore.clearSelectedProducts();
    // eslint-disable-next-line no-void
    void lockersStore.fetchAllLockers();

    navigationStore.sendNavigationAnalytics(`${routes.pages.orders.home}`, routes.tabs.home.lockers);
  });

  return (
    <BodyView>
      <LockersList />
      {Platform.OS !== 'web' && <AuthModal dismissable={false} />}
    </BodyView>
  );
});
