import { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { IconButton } from '@ioupie/components/buttons';
import { Bold } from '@ioupie/components/typography';

import { CustomizeProps } from './customize.models';
import * as S from './customize.styles';

/**
 * @function CustomizeComponent
 */
export default memo((props: CustomizeProps) => {
  const { onPress, text, icon } = props;

  return (
    <TouchableOpacity onPress={onPress}>
      <S.CustomizeContainer>
        <IconButton icon={icon} onPress={onPress} />
        <Bold>{text}</Bold>
      </S.CustomizeContainer>
    </TouchableOpacity>
  );
});
