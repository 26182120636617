import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { NavigationStore } from '@ioupie/stores';

export const useNavigationStore = (): NavigationStore => {
  const navigation = useInjection<NavigationStore>(STORE_TYPES.NAVIGATION);
  return useLocalObservable(() => navigation);
};
