import { memo, useEffect, useState } from 'react';

import { useAuthStore, useSettingsStore } from '@ioupie/hooks';
import { Modal } from '@ioupie/components/custom';
import * as S from './push-notification-modal.styles';
import { BlockButton } from '@ioupie/components/buttons';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

/**
 * @function PushNotificationModal
 */
export default memo(() => {
  const [t] = useTranslation();
  const authStore = useAuthStore();
  const settingsStore = useSettingsStore();

  const [showModal, setShowModal] = useState(
    Platform.OS !== 'web' && !authStore.showAuthModal && !settingsStore.pushNotificationPermissionAlreadyRequested,
  );

  useEffect(() => {
    setShowModal(!authStore.showAuthModal && !settingsStore.pushNotificationPermissionAlreadyRequested);
  }, [authStore.showAuthModal, settingsStore.pushNotificationPermissionAlreadyRequested]);

  const dismissPushNotificationModal = () => {
    setShowModal(false);
    settingsStore.dismissPushNotificationRequest();
    settingsStore.storeUserSettings();
  };

  const requestPushNotificationPermission = () => {
    settingsStore.requestPushNotificationPermission();
    dismissPushNotificationModal();
  };

  return (
    <Modal dismissable visible={showModal} onDismiss={dismissPushNotificationModal}>
      <S.ModalContent>
        <S.ModalTitle>{t('components.push-notification-modal.title')}</S.ModalTitle>
        <S.ModalWarning>{t('components.push-notification-modal.text')}</S.ModalWarning>
        <BlockButton
          reduced
          text={t('components.push-notification-modal.button')}
          onPress={requestPushNotificationPermission}
        />
      </S.ModalContent>
    </Modal>
  );
});
