import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { SettingsStore } from '@ioupie/stores';

export const useSettingsStore = (): SettingsStore => {
  const settings = useInjection<SettingsStore>(STORE_TYPES.SETTINGS);
  return useLocalObservable(() => settings);
};
