export const VALUE_TYPES = Object.freeze({
  SERVICE_FLAGS: Symbol.for('SERVICE-FLAGS-VALUES'),
});

export const SERVICE_TYPES = Object.freeze({
  ANALYTICS: {
    COMPOSITE: Symbol.for('COMPOSITE-ANALYTICS-SERVICE'),
    FIREBASE: Symbol.for('FIREBASE-ANALYTICS-SERVICE'),
    FACEBOOK: Symbol.for('FACEBOOK-ANALYTICS-SERVICE'),
    RUDDER_STACK: Symbol.for('RUDDER-STACK-ANALYTICS-SERVICE'),
    APPS_FLYER: Symbol.for('APPS-FLYER-ANALYTICS-SERVICE'),
    AMPLITUDE: Symbol.for('AMPLITUDE-ANALYTICS-SERVICE'),
  },
  APPEARANCE: Symbol.for('APPEARANCE-SERVICE'),
  CLIPBOARD: Symbol.for('CLIPBOARD-SERVICE'),
  ENCODE: Symbol.for('ENCODE-SERVICE'),
  ERROR: Symbol.for('ERROR-SERVICE'),
  LANGUAGE: Symbol.for('LANGUAGE-SERVICE'),
  LOCATION: Symbol.for('LOCATION-SERVICE'),
  PUSH_NOTIFICATION: Symbol.for('PUSH-NOTIFICATION-SERVICE'),
  REST: Symbol.for('REST-SERVICE'),
  UPDATE: Symbol.for('UPDATE-SERVICE'),
  STORAGE: {
    ASYNC_STORAGE: Symbol.for('ASYNC-STORAGE'),
    SECURE_STORAGE: Symbol.for('SECURE-STORAGE'),
  },
} as const);

export const STORE_TYPES = Object.freeze({
  HEADER: Symbol.for('HEADER-STORE'),
  ADDRESS: Symbol.for('ADDRESS-STORE'),
  AUTH: Symbol.for('AUTH-STORE'),
  BOOTSTRAP: Symbol.for('BOOTSTRAP-STORE'),
  ORDERS: Symbol.for('ORDERS-STORE'),
  DELIVERY: Symbol.for('DELIVERY-STORE'),
  LOCKERS: Symbol.for('LOCKERS-STORE'),
  SEGMENTS: Symbol.for('SEGMENTS-STORE'),
  SETTINGS: Symbol.for('SETTINGS-STORE'),
  SHOPS: Symbol.for('SHOPS-STORE'),
  PAYMENT: Symbol.for('PAYMENT-STORE'),
  USER: Symbol.for('USER-STORE'),
  NAVIGATION: Symbol.for('NAVIGATION-STORE'),
} as const);
