import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { LockersStore } from '@ioupie/stores';

export const useLockersStore = (): LockersStore => {
  const lockers = useInjection<LockersStore>(STORE_TYPES.LOCKERS);
  return useLocalObservable(() => lockers);
};
