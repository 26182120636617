import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { AuthStore } from '@ioupie/stores';

export const useAuthStore = (): AuthStore => {
  const auth = useInjection<AuthStore>(STORE_TYPES.AUTH);
  return useLocalObservable(() => auth);
};
