import { darken, lighten } from 'polished';
import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

import { ListItemProps } from './item.models';

declare type ItemContainerProps = Pick<ListItemProps, 'alignTop' | 'showBorder'>;

export const ItemContainer = styled.SafeAreaView<ItemContainerProps>`
  flex-direction: column;
  border-color: ${({ showBorder }) => {
    return showBorder
      ? themefy({
          light: lighten(0.5, colors.light.text),
          dark: darken(0.5, colors.dark.text),
        })
      : undefined;
  }};
  border-bottom-width: ${({ showBorder }) => {
    return showBorder ? '0.5px' : '0px';
  }};
  margin: 6px 20px;
`;

export const ItemTouchableArea = styled.TouchableOpacity``;

export const ItemContent = styled.View<ItemContainerProps>`
  flex-direction: row;
  padding: 0 0 10px;
  border-color: ${({ showBorder }) => {
    return showBorder
      ? themefy({
          light: colors.light.text,
          dark: colors.dark.text,
        })
      : undefined;
  }};
  border-bottom-width: ${({ showBorder }) => {
    return showBorder ? '0.5px' : '0px';
  }};
`;

export const TopContent = styled.View`
  margin-top: 0;
`;

export const BottomContent = styled.View`
  margin-bottom: 20px;
`;

export const LeftContent = styled.View<ItemContainerProps>`
  justify-content: ${({ alignTop }) => {
    return alignTop ? 'flex-start' : 'center';
  }};
  margin-left: 0;
`;

export const RightContent = styled.View`
  justify-content: center;
  margin-right: 0;
`;

export const BodyContent = styled.View`
  flex: 1;
  justify-content: center;
  margin: 4px 6px;
`;
