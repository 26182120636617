import { Container } from 'inversify';
import { cloneDeep } from 'lodash/fp';
import { Platform } from 'react-native';

import {
  AmplitudeAnalyticsService,
  AppearanceService,
  AppsFlyerAnalyticsService,
  AsyncStorageService,
  ClipboardService,
  CompositeAnalyticsService,
  EncodeService,
  ErrorService,
  FacebookAnalyticsService,
  FirebaseAnalyticsService,
  LanguageService,
  LocationService,
  PushNotificationService,
  RestService,
  RudderStackAnalyticsService,
  SecureStorageService,
  UpdateService,
  type AnalyticsService,
  type StorageService,
} from '@ioupie/services';
import { SERVICE_FLAGS, SERVICE_TYPES, STORE_TYPES, VALUE_TYPES, type ServiceFlags } from '@ioupie/shared/constants';
import {
  AddressStore,
  AuthStore,
  BootstrapStore,
  DeliveryStore,
  HeaderStore,
  LockersStore,
  NavigationStore,
  OrdersStore,
  PaymentStore,
  SegmentsStore,
  SettingsStore,
  ShopsStore,
  UserStore,
} from '@ioupie/stores';

const container = new Container();

container.bind<ServiceFlags>(VALUE_TYPES.SERVICE_FLAGS).toDynamicValue(() => {
  const serviceFlags = cloneDeep(SERVICE_FLAGS);

  return Object.freeze(serviceFlags);
});

container.bind<AnalyticsService>(SERVICE_TYPES.ANALYTICS.COMPOSITE).to(CompositeAnalyticsService).inSingletonScope();
container.bind<AnalyticsService>(SERVICE_TYPES.ANALYTICS.FIREBASE).to(FirebaseAnalyticsService).inSingletonScope();
container.bind<AnalyticsService>(SERVICE_TYPES.ANALYTICS.FACEBOOK).to(FacebookAnalyticsService).inSingletonScope();
container.bind<AnalyticsService>(SERVICE_TYPES.ANALYTICS.AMPLITUDE).to(AmplitudeAnalyticsService).inSingletonScope();
container.bind<AnalyticsService>(SERVICE_TYPES.ANALYTICS.APPS_FLYER).to(AppsFlyerAnalyticsService).inSingletonScope();
container
  .bind<AnalyticsService>(SERVICE_TYPES.ANALYTICS.RUDDER_STACK)
  .to(RudderStackAnalyticsService)
  .inSingletonScope();

container.bind(SERVICE_TYPES.APPEARANCE).to(AppearanceService).inSingletonScope();
container.bind(SERVICE_TYPES.CLIPBOARD).to(ClipboardService).inSingletonScope();
container.bind(SERVICE_TYPES.ENCODE).to(EncodeService).inSingletonScope();
container.bind(SERVICE_TYPES.ERROR).to(ErrorService).inSingletonScope();
container.bind(SERVICE_TYPES.LANGUAGE).to(LanguageService).inSingletonScope();
container.bind(SERVICE_TYPES.LOCATION).to(LocationService).inSingletonScope();
container.bind(SERVICE_TYPES.PUSH_NOTIFICATION).to(PushNotificationService).inSingletonScope();
container.bind(SERVICE_TYPES.REST).to(RestService).inSingletonScope();
container.bind(SERVICE_TYPES.UPDATE).to(UpdateService).inSingletonScope();

container.bind<StorageService>(SERVICE_TYPES.STORAGE.ASYNC_STORAGE).to(AsyncStorageService).inSingletonScope();
// secure storage is not supported on web mode
container
  .bind<StorageService>(SERVICE_TYPES.STORAGE.SECURE_STORAGE)
  .to(Platform.OS === 'web' ? AsyncStorageService : SecureStorageService)
  .inSingletonScope();

container.bind(STORE_TYPES.AUTH).to(AuthStore).inSingletonScope();
container.bind(STORE_TYPES.HEADER).to(HeaderStore).inSingletonScope();
container.bind(STORE_TYPES.BOOTSTRAP).to(BootstrapStore).inSingletonScope();
container.bind(STORE_TYPES.ADDRESS).to(AddressStore).inSingletonScope();
container.bind(STORE_TYPES.ORDERS).to(OrdersStore).inSingletonScope();
container.bind(STORE_TYPES.DELIVERY).to(DeliveryStore).inSingletonScope();
container.bind(STORE_TYPES.LOCKERS).to(LockersStore).inSingletonScope();
container.bind(STORE_TYPES.SEGMENTS).to(SegmentsStore).inSingletonScope();
container.bind(STORE_TYPES.SETTINGS).to(SettingsStore).inSingletonScope();
container.bind(STORE_TYPES.SHOPS).to(ShopsStore).inSingletonScope();
container.bind(STORE_TYPES.PAYMENT).to(PaymentStore).inSingletonScope();
container.bind(STORE_TYPES.USER).to(UserStore).inSingletonScope();
container.bind(STORE_TYPES.NAVIGATION).to(NavigationStore).inSingletonScope();

export { container };
