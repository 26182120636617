import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { ErrorSnackbar } from '@ioupie/components/custom';
import { ScrollView } from '@ioupie/components/layout';
import { useHeaderStore, useLockersStore, useNavigationStore, useOrdersStore } from '@ioupie/hooks';
import { ORDER_STATUS, routes } from '@ioupie/shared/constants';

import { LaundryDetails } from '../laundry-details';
import { PaymentDetails } from '../payment-details';
import { ProductsDetails } from '../products-details';
import { ScheduleDetails } from '../schedule-details';
import * as S from './order-details.styles';

/**
 * @function OrderDetailsContainer
 */
export default observer(() => {
  const { t } = useTranslation();
  const lockersStore = useLockersStore();
  const ordersStore = useOrdersStore();
  const headerStore = useHeaderStore();
  const navigationStore = useNavigationStore();

  const { selectedOrderHistory } = ordersStore;
  const { outboundDetails, status, nps } = selectedOrderHistory ?? {};
  const { lockerId = '' } = outboundDetails ?? {};

  const isNewOrder = ordersStore?.detailsOfNewOrder;
  const isStatusSuccess = status === ORDER_STATUS.SUCCESS;
  const isNpsPending = !isNewOrder && isStatusSuccess && !nps;

  const goBackToDeliveryPage = () => {
    ordersStore.setDetailsOfNewOrder(false);
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.home,
      params: { screen: routes.tabs.home.delivery },
    });
  };

  const navigateToNpsEvaluation = (): void => {
    ordersStore.setDetailsOfNewOrder(false);
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.nps_evaluation,
    });
  };

  headerStore.onClickBack(isNewOrder ? goBackToDeliveryPage : undefined);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void lockersStore.selectLockerProvider(lockerId);
  }, []);

  return (
    <Fragment>
      <ScrollView>
        <LaundryDetails />
        {isNewOrder ? (
          <S.MessageBoxContainer>
            <S.MessageBoxContent>
              <S.MessageBoxIcon name="check-decagram" size={20} />
              <S.MessageBoxTitle>{t('containers.orders.track-order.order-details.thank-you')}</S.MessageBoxTitle>
            </S.MessageBoxContent>
          </S.MessageBoxContainer>
        ) : undefined}
        {isNpsPending ? (
          <S.MessageBoxContainer>
            <S.MessageBoxContent>
              <S.MessageBoxIcon name="star-outline" size={24} />
              <S.MessageBoxTitle>{t('containers.orders.track-order.order-details.evaluate-order')}</S.MessageBoxTitle>
            </S.MessageBoxContent>
            <BlockButton
              text={t('containers.orders.track-order.order-details.evaluate-button')}
              onPress={navigateToNpsEvaluation}
              reduced
              small
              autoWidth
            />
          </S.MessageBoxContainer>
        ) : undefined}
        <ProductsDetails />
        <PaymentDetails />
        <ScheduleDetails />
        <BlockButton
          loading={ordersStore.loading}
          text={t('containers.orders.track-order.order-details.back-button')}
          onPress={ordersStore.detailsOfNewOrder ? navigateToNpsEvaluation : goBackToDeliveryPage}
        />
      </ScrollView>
      <ErrorSnackbar errors={ordersStore.errors} onDismiss={() => ordersStore.clearErrors()} />
    </Fragment>
  );
});
