import { Appbar } from 'react-native-paper';
import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';
import { darken, lighten } from 'polished';

declare type HeaderBarProps = {
  readonly noShadows?: boolean;
};

export default styled(Appbar.Header)<HeaderBarProps>`
  background-color: ${themefy({
    light: lighten(0, colors.light.primary),
    dark: darken(0, colors.dark.primary),
  })};
  elevation: ${({ noShadows }) => {
    return noShadows ? 0 : 4;
  }};
  position: relative;
`;
