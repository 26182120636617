import { darken, lighten } from 'polished';
import { StyleSheet } from 'react-native';

import { colors, fontsFamily } from '@ioupie/shared/constants';

export const styles = (mode: 'light' | 'dark') => {
  return StyleSheet.create({
    tabsOptions: {
      backgroundColor: mode === 'light' ? lighten(0.0, colors.light.primary) : darken(0.0, colors.dark.background),
      borderTopColor: mode === 'light' ? lighten(0.2, colors.light.secondary) : darken(0.2, colors.dark.primary),
      borderTopWidth: 1,
      fontFamily: fontsFamily.medium,
      padding: 5,
    },
  });
};
