import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

import { ListMessageProps } from './message.models';

declare type MessageProps = ListMessageProps;

export const MessageBody = styled.View`
  flex-direction: column;
  justify-content: center;
`;

export const ItemTitle = styled(Text)<MessageProps>`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.semibold};
  font-size: 17px;
  font-weight: ${fontsWeight.semibold};
  padding-bottom: 5px;
`;
