import 'expo-asset';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/pt';
import 'react-native-gesture-handler';
import 'react-native-get-random-values';
import 'reflect-metadata';

import * as Sentry from '@sentry/react-native';
import { Platform } from 'react-native';

import environment from '@ioupie/env';
import { AppRoutes } from '@ioupie/routes';

import * as development from './dev';
import './global';
import Main from './src';

if (__DEV__ && Platform.OS !== 'web') {
  development.disableTimerLog();
  development.disableIconsLog();
  development.enableNetworkDebug();
}

Sentry.init({
  dsn: environment.SENTRY_DSN,
  // uncomment the line below to enable Spotlight (https://spotlightjs.com)
  // enableSpotlight: __DEV__,
});

export default Sentry.wrap(() => {
  return (
    <Main>
      <AppRoutes />
    </Main>
  );
});
