import {
  Montserrat_100Thin,
  Montserrat_300Light,
  Montserrat_400Regular,
  Montserrat_500Medium,
  Montserrat_600SemiBold,
  Montserrat_700Bold,
} from '@expo-google-fonts/montserrat';
import Constants from 'expo-constants';
import { loadAsync } from 'expo-font';
import { PermissionStatus } from 'expo-location';
import { Manifest } from 'expo-updates';
import i18n, { LanguageDetectorAsyncModule } from 'i18next';
import { inject, injectable, postConstruct } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { initReactI18next } from 'react-i18next';
import { Platform } from 'react-native';
import semver from 'semver';

import englishTranslations from '@ioupie/assets/i18n/en.json';
import portugueseTranslations from '@ioupie/assets/i18n/pt.json';
import {
  AppearanceService,
  ErrorService,
  LanguageService,
  LocationService,
  PushNotificationService,
  RestService,
  UpdateService,
  type AnalyticsService,
  type StorageService,
} from '@ioupie/services';
import {
  AnalyticsEvents,
  FALLBACK_LANGUAGE,
  SERVICE_TYPES,
  STORAGE_KEYS,
  STORE_TYPES,
  SUPPORTED_LANGUAGES,
  endpoints,
  fontsFamily,
} from '@ioupie/shared/constants';
import type { ErrorMessages } from '@ioupie/shared/models';
import { PushNotificationToken, PushNotificationTokenStatus, StoredSettings } from '@ioupie/shared/models';

import { AuthStore } from './auth.store';

@injectable()
export class SettingsStore {
  private static readonly FALLBACK_LIGHT_MODE: Readonly<boolean> = false;
  private static readonly PUSH_NOTIFICATIONS_REQUESTED_FALLBACK: Readonly<boolean> = false;

  @inject(SERVICE_TYPES.APPEARANCE)
  private readonly appearanceService: AppearanceService;
  @inject(SERVICE_TYPES.LANGUAGE)
  private readonly languageService: LanguageService;
  @inject(SERVICE_TYPES.STORAGE.ASYNC_STORAGE)
  private readonly storageService: StorageService;
  @inject(SERVICE_TYPES.LOCATION)
  private readonly locationService: LocationService;
  @inject(SERVICE_TYPES.PUSH_NOTIFICATION)
  private readonly pushNotificationService: PushNotificationService;
  @inject(SERVICE_TYPES.ERROR)
  private readonly errorService: ErrorService;
  @inject(SERVICE_TYPES.REST)
  private readonly restService: RestService;
  @inject(SERVICE_TYPES.UPDATE)
  private readonly updateService: UpdateService;
  @inject(SERVICE_TYPES.ANALYTICS.COMPOSITE)
  private readonly analyticsService: AnalyticsService;

  @inject(STORE_TYPES.AUTH)
  private readonly authStore: AuthStore;

  @observable public locationPermission?: PermissionStatus;
  @observable public pushNotificationPermission?: PermissionStatus;
  @observable public pushNotificationPermissionAlreadyRequested: boolean;

  @observable public deviceLanguage?: string;
  @observable public deviceDarkMode?: boolean;

  @observable public language?: string;
  @observable public darkMode?: boolean;

  @observable public loading: boolean = false;
  @observable public errors: ErrorMessages = [];

  @observable public updatesAvailable: boolean = false;
  @observable public settingsLoaded: boolean = false;

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public setDarkMode(darkMode: boolean): void {
    this.darkMode = darkMode;
  }

  @action
  private checkExpoAppManifest(manifest?: unknown): manifest is Manifest {
    // eslint-disable-next-line no-null/no-null
    return manifest !== undefined && manifest !== null && typeof manifest === 'object';
  }

  @action
  public async checkForAvailableUpdates(): Promise<void> {
    if (__DEV__ || Platform.OS === 'web') {
      return;
    }

    try {
      const updateData = await this.updateService.checkForUpdates();

      if (updateData.isAvailable && this.checkExpoAppManifest(updateData.manifest)) {
        const { version: appVersion = '' } = Constants.expoConfig ?? {};
        const { id: newVersion = '' } = updateData.manifest ?? {};

        // only perform OTA on patch changes
        runInAction(() => {
          this.updatesAvailable = semver.diff(appVersion, newVersion) === 'patch';
        });
      } else {
        runInAction(() => {
          this.updatesAvailable = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.updatesAvailable = false;
      });
    }
  }

  @action
  public async updateAndReloadApp(): Promise<void> {
    this.loading = true;

    try {
      await this.updateService.fetchRemoteUpdates();
      await this.updateService.reloadApp();

      runInAction(() => {
        this.updatesAvailable = false;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async fetchDeviceLocationPermission(): Promise<void> {
    this.loading = true;

    try {
      const permission = await this.locationService.getCurrentPermission();

      runInAction(() => {
        this.locationPermission = permission.status;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async requestDeviceLocationPermission(): Promise<void> {
    this.loading = true;

    try {
      const permission = await this.locationService.requestForegroundPermission();

      runInAction(() => {
        this.locationPermission = permission.status;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async fetchPushNotificationPermission(): Promise<void> {
    this.loading = true;

    try {
      const permission = await this.pushNotificationService.getCurrentPermission();

      runInAction(() => {
        this.pushNotificationPermission = permission;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async disablePushNotificationPermission(): Promise<void> {
    this.loading = true;

    try {
      const token = await this.pushNotificationService.getExpoPushToken();

      if (this.pushNotificationService.isValidExpoToken(token)) {
        const phoneToken: PushNotificationToken = {
          token,
          status: PushNotificationTokenStatus.inactive,
        };
        await this.restService.post<PushNotificationToken, unknown>(
          endpoints.portaria.user.persist_phone_token,
          phoneToken,
          this.authStore.buildAuthHeaders(),
        );
      }

      runInAction(() => {
        this.pushNotificationPermission = PermissionStatus.DENIED;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async requestPushNotificationPermission(): Promise<void> {
    this.loading = true;

    try {
      const permission = await this.pushNotificationService.requestPermissions();
      const token = await this.pushNotificationService.getExpoPushToken();

      if (this.pushNotificationService.isValidExpoToken(token)) {
        const phoneToken: PushNotificationToken = {
          token,
          status: PushNotificationTokenStatus.active,
        };
        await this.restService.post<PushNotificationToken, unknown>(
          endpoints.portaria.user.persist_phone_token,
          phoneToken,
          this.authStore.buildAuthHeaders(),
        );
      }

      await this.pushNotificationService.setNotificationChannel();

      runInAction(() => {
        this.pushNotificationPermission = permission;
        this.pushNotificationPermissionAlreadyRequested = true;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public dismissPushNotificationRequest(): void {
    this.pushNotificationPermissionAlreadyRequested = true;
  }

  @action
  public async fetchDeviceAppearance(): Promise<void> {
    this.loading = true;

    try {
      const appearance = await this.appearanceService.getUserAppearance();

      this.analyticsService.trackEvent(AnalyticsEvents.DEVICE_SET_APPEARANCE, { appearance });

      runInAction(() => {
        this.deviceDarkMode = appearance === 'dark';
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async bootstrapSettings(): Promise<void> {
    this.loading = true;

    try {
      const settings = await this.storageService.retrieveData<StoredSettings>(STORAGE_KEYS.SETTINGS);
      const localization = await this.languageService.getUserLocale();

      // web defaults to portuguese
      const language =
        Platform.OS === 'web'
          ? SUPPORTED_LANGUAGES.PORTUGUESE
          : (settings?.language ?? localization?.locale ?? FALLBACK_LANGUAGE);

      const languageDetector: LanguageDetectorAsyncModule = {
        type: 'languageDetector',
        async: true,
        init: () => undefined,
        detect: (callback) => callback(language),
        cacheUserLanguage: () => undefined,
      } as const;

      await i18n
        .use(languageDetector)
        .use(initReactI18next)
        .init({
          fallbackLng: FALLBACK_LANGUAGE,
          resources: {
            [SUPPORTED_LANGUAGES.ENGLISH]: { translation: englishTranslations },
            [SUPPORTED_LANGUAGES.PORTUGUESE]: { translation: portugueseTranslations },
          },
          ns: ['translation'],
          nsSeparator: '|',
          defaultNS: 'translation',
          interpolation: {
            escapeValue: false,
          },
        });

      // change the language after i18n has been initialized
      await this.languageService.changeAppLanguage(language);

      // load the fonts used system wide
      await loadAsync({
        [fontsFamily.bold]: {
          uri: Montserrat_700Bold,
        },
        [fontsFamily.semibold]: {
          uri: Montserrat_600SemiBold,
        },
        [fontsFamily.medium]: {
          uri: Montserrat_500Medium,
        },
        [fontsFamily.regular]: {
          uri: Montserrat_400Regular,
        },
        [fontsFamily.light]: {
          uri: Montserrat_300Light,
        },
        [fontsFamily.thin]: {
          uri: Montserrat_100Thin,
        },
      });

      const appearance = await this.appearanceService.getUserAppearance();
      const deviceDarkMode = appearance && appearance === 'dark';

      const darkMode = settings?.darkMode ?? deviceDarkMode ?? SettingsStore.FALLBACK_LIGHT_MODE;

      const pushNotificationPermissionAlreadyRequested =
        settings?.pushNotificationPermissionAlreadyRequested ?? SettingsStore.PUSH_NOTIFICATIONS_REQUESTED_FALLBACK;

      await this.storageService.persistData<StoredSettings>(STORAGE_KEYS.SETTINGS, {
        darkMode,
        language,
        pushNotificationPermissionAlreadyRequested,
      });

      // if nothing on stored values, stick with the defaults from this class
      runInAction(() => {
        this.deviceLanguage = localization.locale;
        this.deviceDarkMode = appearance === 'dark';

        this.darkMode = darkMode;
        this.language = language;

        this.pushNotificationPermissionAlreadyRequested = pushNotificationPermissionAlreadyRequested;

        this.settingsLoaded = true;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async fetchDeviceLanguage(): Promise<void> {
    this.loading = true;

    try {
      const localization = await this.languageService.getUserLocale();

      this.analyticsService.trackEvent(AnalyticsEvents.DEVICE_FETCH_LANGUAGE, { localization });

      runInAction(() => {
        this.deviceLanguage = localization.locale;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async changeLanguage(language: string): Promise<void> {
    this.loading = true;

    try {
      await this.languageService.changeAppLanguage(language);

      this.analyticsService.trackEvent(AnalyticsEvents.DEVICE_CHANGE_LANGUAGE, { language });

      runInAction(() => {
        this.language = language;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async fetchStoredSettings(): Promise<void> {
    this.loading = true;

    try {
      const settings = await this.storageService.retrieveData<StoredSettings>(STORAGE_KEYS.SETTINGS);

      // if nothing on stored values, stick with the defaults from this class
      runInAction(() => {
        this.darkMode = settings?.darkMode ?? this.darkMode;
        this.language = settings?.language ?? this.language;
        this.pushNotificationPermissionAlreadyRequested =
          settings?.pushNotificationPermissionAlreadyRequested ?? this.pushNotificationPermissionAlreadyRequested;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async storeUserSettings(): Promise<void> {
    this.loading = true;

    try {
      await this.storageService.persistData<StoredSettings>(STORAGE_KEYS.SETTINGS, {
        darkMode: this.darkMode,
        language: this.language,
        pushNotificationPermissionAlreadyRequested: this.pushNotificationPermissionAlreadyRequested,
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @computed
  public get normalizedLanguage(): string {
    if (!this.language) {
      return 'pt-BR'; // fallback
    }

    if (this.language === 'pt') {
      return 'pt-BR'; // append BR
    }

    if (this.language === 'en') {
      return 'en-US'; // append US
    }

    // probably is fully qualified
    return this.language;
  }
}
