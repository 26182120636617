import { useInjection } from 'inversify-react';
import { useLocalObservable } from 'mobx-react-lite';

import { STORE_TYPES } from '@ioupie/shared/constants';
import { ShopsStore } from '@ioupie/stores';

export const useShopsStore = (): ShopsStore => {
  const shops = useInjection<ShopsStore>(STORE_TYPES.SHOPS);
  return useLocalObservable<ShopsStore>(() => shops);
};
