/* eslint-disable no-void */
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import firebase from '@react-native-firebase/app';
import rudderStack from '@rudderstack/rudder-sdk-react-native';
import { inject, injectable } from 'inversify';
import { browserName, browserVersion, isIOS, isMacOs, osName, osVersion } from 'react-device-detect';
import appsFlyer from 'react-native-appsflyer';

import { AnalyticsConstants, AnalyticsEvents, VALUE_TYPES, type ServiceFlags } from '@ioupie/shared/constants';
import { type UserProfile } from '@ioupie/shared/models';

import { Platform } from 'react-native';
import { type AnalyticsService } from './analytics.service';
import { BaseAnalyticsService } from './base-analytics.service';

@injectable()
export class RudderStackAnalyticsService extends BaseAnalyticsService implements AnalyticsService {
  private static readonly EMPTY_LIST: readonly string[] = [] as const;

  public constructor(@inject(VALUE_TYPES.SERVICE_FLAGS) private readonly serviceFlags: ServiceFlags) {
    super();
  }

  public async setUser(userId: string) {
    if (!this.serviceFlags.analytics.rudderStack.enable) {
      return Promise.resolve();
    }

    try {
      const externalId = await this.buildExternalTokens();
      const webMetaData = await this.buildWebMetaData();

      await rudderStack.identify(
        userId,
        {},
        {
          externalId,
          ...webMetaData,
        },
      );
    } catch (error) {
      console.error(`RudderStack user [${userId}] error`, error);
    }
  }

  public async setUserProperties(userProps: UserProfile) {
    if (!this.serviceFlags.analytics.rudderStack.enable) {
      return Promise.resolve();
    }

    try {
      const userId: string = userProps.subject ?? userProps.username;

      const externalId = await this.buildExternalTokens();
      const webMetaData = await this.buildWebMetaData();

      await rudderStack.identify(
        userId,
        {
          firstName: userProps.name,
          lastName: userProps.familyName,
          email: userProps.email,
          phone: userProps.phoneNumber,
          birthday: userProps.birthDate,
          username: userId,
        },
        {
          externalId,
          ...webMetaData,
        },
      );
    } catch (error) {
      console.error(`RudderStack user [${userProps?.subject}] properties error`, error);
    }
  }

  public async setScreen(screen: string, metaData: Record<string, string> = {}) {
    if (!this.serviceFlags.analytics.rudderStack.enable) {
      return Promise.resolve();
    }

    try {
      const eventPayload = this.buildScreenPayload(screen, this.convert(metaData));

      const externalId = await this.buildExternalTokens();
      const webMetaData = await this.buildWebMetaData();

      await rudderStack.screen(screen, eventPayload, {
        externalId,
        ...webMetaData,
      });
    } catch (error) {
      console.error(`RudderStack screen [${screen}] error`, error);
    }
  }

  public async trackEvent(event: AnalyticsEvents, eventData: Record<string, any> = {}) {
    if (!this.serviceFlags.analytics.rudderStack.enable) {
      return Promise.resolve();
    }

    try {
      const prefixedEventName = this.prefixEventName(AnalyticsConstants.RudderStack[event]);

      const externalId = await this.buildExternalTokens();
      const webMetaData = await this.buildWebMetaData();

      await rudderStack.track(prefixedEventName, this.convert(eventData), {
        externalId,
        ...webMetaData,
      });
    } catch (error) {
      console.error(`RudderStack event [${event}] error`, error);
    }
  }

  public async trackUnprefixedEvent(event: AnalyticsEvents, eventData: Record<string, any> = {}) {
    if (!this.serviceFlags.analytics.rudderStack.enable) {
      return Promise.resolve();
    }

    try {
      const prefixedEventName = this.prefixNativeEventName(AnalyticsConstants.RudderStack[event]);

      const externalId = await this.buildExternalTokens();
      const webMetaData = await this.buildWebMetaData();

      await rudderStack.track(prefixedEventName, this.convert(eventData), {
        externalId,
        ...webMetaData,
      });
    } catch (error) {
      console.error(`RudderStack native event [${event}] error`, error);
    }
  }

  private async buildWebMetaData() {
    if (Platform.OS !== 'web') {
      return {};
    }

    try {
      const agent = await FingerprintJS.load();
      const fingerprint = await agent.get();

      return {
        device: {
          id: fingerprint.visitorId,
          type: isIOS || isMacOs ? 'ios' : 'android', // facebook only accepts ios or android
          name: browserName,
          version: browserVersion,
        },
        os: {
          name: osName,
          version: osVersion,
        },
      } as const;
    } catch (error) {
      console.error('RudderStack web metadata error', error);
      return {};
    }
  }

  private async buildExternalTokens() {
    try {
      const g4 = await this.buildG4External();
      const af = await this.buildAppsFlyerExternal();

      return [...g4, ...af] as const;
    } catch (error) {
      console.error('RudderStack external tokens error', error);
      return RudderStackAnalyticsService.EMPTY_LIST;
    }
  }

  private async buildG4External() {
    if (!this.serviceFlags.analytics.firebase.enable) {
      return RudderStackAnalyticsService.EMPTY_LIST;
    }

    try {
      return [
        {
          id: firebase.app().options.clientId,
          type: 'ga4ClientId',
        },
        {
          id: firebase.app().options.appId,
          type: 'ga4AppInstanceId',
        },
      ] as const;
    } catch (error) {
      console.error('Failed generating RudderStack G4 external', error);
      return RudderStackAnalyticsService.EMPTY_LIST;
    }
  }

  private async buildAppsFlyerExternal() {
    // AppsFlyer doesn't have support to web
    if (Platform.OS === 'web') {
      return RudderStackAnalyticsService.EMPTY_LIST;
    }

    if (!this.serviceFlags.analytics.appsFlyer.enable) {
      return RudderStackAnalyticsService.EMPTY_LIST;
    }

    try {
      const appsFlyerUID = new Promise<string>((resolve, reject) =>
        appsFlyer.getAppsFlyerUID((error, uid) => {
          if (error) {
            reject(error);
          } else {
            resolve(uid);
          }
        }),
      );

      return [
        {
          id: await appsFlyerUID,
          type: 'appsflyerExternalId',
        },
      ] as const;
    } catch (error) {
      console.error('Failed generating RudderStack AppsFlyer external', error);
      return RudderStackAnalyticsService.EMPTY_LIST;
    }
  }
}
